import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormatMessage } from 'hooks';
import {
  authCleanUp
} from 'state/actions/auth';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import GuestNav from 'components/Navigation/GuestNav';
import logo from '../../assets/images/logo_transparent.png';
import classes from './Guest.module.scss';

const Guest = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  return (
    <section className="section hero">
      <div className="hero-head">
        <GuestNav />
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <img className={classNames(classes.logo)} src={logo} alt='Logo of ETA Logistics' />
              <p className="title">{useFormatMessage('Guest.title')}</p>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <a
                href='/guest/quotes/dap'
                className={classNames(
                  'button',
                  'is-fullwidth',
                  'is-large',
                  classes.buttonChoice
                )}
              >DAP</a>
            </div>
            <div className="column has-text-centered">
              <button
                onClick={() => toastr.error('', 'Coming Soon in next update.')}
                className={classNames(
                  'button',
                  'is-fullwidth',
                  'is-large',
                  classes.buttonChoice
                )}
              >Air Export</button>
            </div>
            <div className="column has-text-centered">
              <a
                href='/guest/quotes/ftl'
                className={classNames(
                  'button',
                  'is-fullwidth',
                  'is-large',
                  classes.buttonChoice
                )}
              >FTL</a>
            </div>
            <div className="column has-text-centered">
              <a
                href='/guest/quotes/ltl'
                className={classNames(
                  'button',
                  'is-fullwidth',
                  'is-large',
                  classes.buttonChoice
                )}
              >LTL</a>
            </div>
            <div className="column has-text-centered">
              <button 
                onClick={() => toastr.error('', 'Coming Soon in next update.')}
                className={classNames(
                  'button',
                  'is-fullwidth',
                  'is-large',
                  classes.buttonChoice
                )}
              >Courier</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guest;
