import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import Guest from 'pages/Guest';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import FTL from 'pages/FTL';
import FTLs from 'pages/FTLs';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import QuotesDAP from '../Quotes/DAP';
import QuotesLTL from '../Quotes/LTL';
import QuotesFTL from '../Quotes/FTL';
import QuotesAirExport from '../Quotes/AirExport';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.SIGNUP} component={SignUp} />
        <Route exact path={paths.GUEST} component={Guest} />
        <Route exact path={paths.QUOTESDAP} component={QuotesDAP} />
        <Route exact path={paths.QUOTESAIREXPORT} component={QuotesAirExport} />
        <Route exact path={paths.QUOTESLTL} component={QuotesLTL} />
        <Route exact path={paths.QUOTESFTL} component={QuotesFTL} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.ADD_FTL} component={FTL} />
        <PrivateRoute path={paths.MODIFY_FTL} component={FTL} />
        <PrivateRoute path={paths.FTLS} component={FTLs} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.SUBMENU_1} component={Submenu} />
        <PrivateRoute path={paths.SUBMENU_2} component={Submenu} />
        <PrivateRoute exact path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
