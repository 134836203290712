import React, { useEffect, Fragment } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Parser from 'html-react-parser';
import { data } from './data';

const unitsFormat = {
  'imperial': {
    'weight': 'LB',
    'length': 'INCH'
  },
  'metric': {
    'weight': 'KG',
    'length': 'M'
  }
};

const QuotesDAPPackages = ({ prevStep, nextStep, updatePackages, handleChange, values }) => {
  const schema = yup.object().shape({
    packages: yup.array().of(
      yup.object().shape({
        format: yup.string().required(),
        quantity: yup.number().required().positive().integer(),
        pieces: yup.number().required().positive().integer(),
        length: yup.number().required().positive(),
        width: yup.number().required().positive(),
        height: yup.number().required().positive(),
        weight: yup.number().required().positive(),
      })
    ).required()
  });

  const formOptions = { resolver: yupResolver(schema) };

  const { register, control, handleSubmit, watch } = useForm(formOptions);
  const { fields, append, remove, replace } = useFieldArray({ name: 'packages', control });
  const watchPackages = watch('packages');

  const onSubmitHandler = () => {
    nextStep();
  };

  useEffect(() => {
    replace(values.packages);
  }, []);

  useEffect(() => {
    updatePackages(watchPackages);
  }, [watchPackages]);

  const pushPackage = () => {
    const pack = {
      format: '', quantity: 0, pieces: 0, length: 0, width: 0, height: 0, weight: 0
    };
    append(pack);
  };

  const removeLastPackage = (index) => {
    remove(index);
  };

  /* eslint-disable */
  return (
    <section className="section hero">
      <div className="box"><>{ Parser(data[values.from].disclaimer) }</></div>
      <div className="box">
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <p>Measurements Units</p>
          <div className="field">
            <div className="control">
              <label htmlFor="unitsMetric" className="radio">
                <input
                  {...register("units")}
                  onChange={handleChange('units')}
                  type="radio"
                  value="metric"
                  name="units"
                  id="unitsMetric"
                  checked={ values.units === "metric" }
                />
                <span style={{marginLeft:8}}>Metric (M and KG)</span>
              </label>
              <label htmlFor="unitsImperial" className="radio">
                <input
                  {...register("units")}
                  onChange={handleChange('units')}
                  type="radio"
                  value="imperial"
                  name="units"
                  id="unitsImperial"
                  checked={ values.units === "imperial" }
                />
                <span style={{ marginLeft:8 }}>Imperial (INCH and LBS)</span>
              </label>
            </div>
          </div>

          { data[values.from].method === "skids" &&
            <Fragment>
              <p>Number of Skids</p>
              <div className="field is-grouped">
                <div className="control">
                  <input
                    {...register("skids")}
                    onChange={handleChange('skids')}
                    className="input"
                    required
                    type="number"
                    name="skids"
                    min="0"
                    max="10"
                    step="1"
                  />
                </div>
              </div>
            </Fragment>
          }

          <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => pushPackage() }
              >Add a package</button>
            </p>
            {fields.length > 0 &&
              <p className="control">
                <button
                  type="button"
                  className='button is-black'
                  onClick={() => removeLastPackage(fields.length - 1) }
                >Remove last package</button>
              </p>
            }
          </div>
          
          {/* <div className="field">
            <p className='title is-5'>Number of packages</p>
            <div className="control is-clearfix">
              <div className='select'>
                <select name="numberOfPackages" ref={register} onChange={handleChange('numberOfPackages')}>
                  {['', 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i =>
                    <option key={i} value={i}>{i}</option>
                  )}
                </select>
              </div>
            </div>
          </div> */}

          {fields.map((item, i) => (
            // <div className="field box" key={uuid()}>
            <div className="field box" key={item.id}>
              <p className="is-size-5">Package {i+1}</p>
              <div className="field-body">

                <div className="field">
                  <p className="label">Format</p>
                  <div className="control is-clearfix">
                    <div className="select">
                      <select
                        name={`packages.${i}.format`}
                        {...register(`packages.${i}.format`)}
                      >
                        <option value=""></option>
                        <option value="pallet">Pallet</option>
                        <option value="skid">Skid</option>
                        <option value="box">Box</option>
                        <option value="bundle">Bundle</option>
                        <option value="crate">Crate</option>
                        <option value="crate">Cloth Tote</option>
                        <option value="crate">Fluid Tote</option>
                        <option value="crate">Drum</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Quantity</p>
                  <div className="control is-clearfix">
                    <input
                      type="number"
                      className='input'
                      name={`packages.${i}.quantity`}
                      {...register(`packages.${i}.quantity`)}
                    />
                  </div>
                </div>

                <div className="field">
                  <p className="label">Pieces</p>
                  <div className="control is-clearfix">
                    <input
                      type="number"
                      className='input'
                      name={`packages.${i}.pieces`}
                      {...register(`packages.${i}.pieces`)}
                    />
                  </div>
                </div>

                <div className="field">
                  <p className="label">Length</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`packages.${i}.length`}
                        {...register(`packages.${i}.length`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.units].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Width</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`packages.${i}.width`}
                        {...register(`packages.${i}.width`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.units].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Height</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`packages.${i}.height`}
                        {...register(`packages.${i}.height`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                      <button className='button is-static'>{ unitsFormat[values.units].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Weight per item</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`packages.${i}.weight`}
                        {...register(`packages.${i}.weight`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.units].weight }</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          ))}

          {fields.length > 0
            ? <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => prevStep() }
              >Previous</button>
            </p>
            <p className="control">
              <button
                type="submit"
                className='button is-black'
              >Next</button>
            </p>
          </div>
            : <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => prevStep() }
              >Previous</button>
            </p>
          </div>
          }
          
        </form>
      </div>

    </section>
  );
  /* eslint-enable */
};

export default QuotesDAPPackages;