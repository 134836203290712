export const data = {
    "YUL": {
      "disclaimer": `<p>Delivery in this zone is calculated by "Skid Spot". It is your responsability to provide us with accurate information based on the below criteria.</p><p>IMPERIAL MEASUREMENT: A "Skid Spot" is 48" L x 40" W x 79 H (max) with a maximum weight of 1000 lbs per unit.</p><p>METRIC MEASUREMENT:  A "Skid Spot" is 122 L x 102 W x 200 H cm (max) with a maximum weight of 454 kg per unit.</p><p>For any shipping unit surpassing the above measurement or weight, add an additionnal "Skid Spot".</p><p>Local deliveries of 10 skid spots or less are completed with 22' straight body dry van trucks.</p><p>Local deliveries of 11 skid spots or more are completed with 53' tractor/trailor dry vans, these cannot be quoted here and require you send an e-mail to DAP@ETALOGISTICS.CA.</p><p>Tailgate deliveries not available for more than 10 pallets, please contact  DAP@ETALOGISTICS.CA for requests that are outside of the norm or "out of zone"</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 60.00 USD / H charged in 15 min increments.</p>`,
      "perKilo": [],
      "fuelSurchage": 0.21,
      "method": "skids",
      "carbonTax": 0,
      "COVIDAirportFees": 0,
      "terminalFees": 90,
      "handlingFees": 50,
      "zones": {
        1: {
          "rates": [
            { "skids": 1, "price": 42 },
            { "skids": 2, "price": 52 },
            { "skids": 3, "price": 64 },
            { "skids": 4, "price": 75 },
            { "skids": 5, "price": 85 },
            { "skids": 6, "price": 96 },
            { "skids": 7, "price": 107 },
            { "skids": 8, "price": 118 },
            { "skids": 9, "price": 130 },
            { "skids": 10, "price": 140 },
          ]
        },
        2: {
          "rates": [
            { "skids": 1, "price": 44 },
            { "skids": 2, "price": 57 },
            { "skids": 3, "price": 70 },
            { "skids": 4, "price": 83 },
            { "skids": 5, "price": 96 },
            { "skids": 6, "price": 109 },
            { "skids": 7, "price": 1126 },
            { "skids": 8, "price": 143 },
            { "skids": 9, "price": 153 },
            { "skids": 10, "price": 158 },
          ]
        },
        3: {
          "rates": [
            { "skids": 1, "price": 55 },
            { "skids": 2, "price": 70 },
            { "skids": 3, "price": 86 },
            { "skids": 4, "price": 103 },
            { "skids": 5, "price": 119 },
            { "skids": 6, "price": 135 },
            { "skids": 7, "price": 152 },
            { "skids": 8, "price": 168 },
            { "skids": 9, "price": 180 },
            { "skids": 10, "price": 185 },
          ]
        },
        4: {
          "rates": [
            { "skids": 1, "price": 60 },
            { "skids": 2, "price": 73 },
            { "skids": 3, "price": 89 },
            { "skids": 4, "price": 106 },
            { "skids": 5, "price": 123 },
            { "skids": 6, "price": 138 },
            { "skids": 7, "price": 155 },
            { "skids": 8, "price": 172 },
            { "skids": 9, "price": 183 },
            { "skids": 10, "price": 188 },
          ]
        },
        5: {
          "rates": [
            { "skids": 1, "price": 73 },
            { "skids": 2, "price": 89 },
            { "skids": 3, "price": 106 },
            { "skids": 4, "price": 123 },
            { "skids": 5, "price": 138 },
            { "skids": 6, "price": 155 },
            { "skids": 7, "price": 172 },
            { "skids": 8, "price": 183 },
            { "skids": 9, "price": 188 },
            { "skids": 10, "price": 193 },
          ]
        }
      },
      "postcodes": {
        "H0A":	6,
        "H0B":	6,
        "H0C":	6,
        "H0E":	6,
        "H0G":	6,
        "H0H":	6,
        "H0J":	6,
        "H0K":	6,
        "H0L":	6,
        "H0M":	7,
        "H0N":	6,
        "H0P":	6,
        "H0R":	6,
        "H0S":	6,
        "H0T":	6,
        "H0V":	6,
        "H0W":	6,
        "H0X":	6,
        "H0Y":	6,
        "H0Z":	6,
        "H1A":	4,
        "H1B":	4,
        "H1C":	4,
        "H1E":	4,
        "H1G":	1,
        "H1H":	1,
        "H1J":	1,
        "H1K":	1,
        "H1L":	1,
        "H1M":	1,
        "H1N":	1,
        "H1P":	1,
        "H1R":	1,
        "H1S":	1,
        "H1T":	1,
        "H1V":	1,
        "H1W":	1,
        "H1X":	1,
        "H1Y":	1,
        "H1Z":	1,
        "H2A":	1,
        "H2B":	1,
        "H2C":	1,
        "H2E":	1,
        "H2G":	1,
        "H2H":	1,
        "H2J":	1,
        "H2K":	1,
        "H2L":	1,
        "H2M":	1,
        "H2N":	1,
        "H2P":	1,
        "H2R":	1,
        "H2S":	1,
        "H2T":	1,
        "H2V":	1,
        "H2W":	1,
        "H2X":	1,
        "H2Y":	1,
        "H2Z":	1,
        "H3A":	1,
        "H3B":	1,
        "H3C":	1,
        "H3E":	1,
        "H3G":	1,
        "H3H":	1,
        "H3J":	1,
        "H3K":	1,
        "H3L":	1,
        "H3M":	1,
        "H3N":	1,
        "H3P":	1,
        "H3R":	1,
        "H3S":	1,
        "H3T":	1,
        "H3V":	1,
        "H3W":	1,
        "H3X":	1,
        "H3Y":	1,
        "H3Z":	1,
        "H4A":	1,
        "H4B":	1,
        "H4C":	1,
        "H4E":	1,
        "H4G":	1,
        "H4H":	1,
        "H4J":	1,
        "H4K":	1,
        "H4L":	1,
        "H4M":	1,
        "H4N":	1,
        "H4P":	1,
        "H4R":	1,
        "H4S":	1,
        "H4T":	1,
        "H4V":	1,
        "H4W":	1,
        "H4X":	1,
        "H4Y":	1,
        "H4Z":	1,
        "H5A":	1,
        "H5B":	1,
        "H5C":	6,
        "H5E":	6,
        "H5G":	6,
        "H5H":	6,
        "H5J":	6,
        "H5K":	6,
        "H5L":	6,
        "H5M":	6,
        "H5N":	6,
        "H5P":	6,
        "H5R":	6,
        "H5S":	6,
        "H5T":	6,
        "H5V":	6,
        "H5W":	6,
        "H5X":	6,
        "H5Y":	6,
        "H5Z":	6,
        "H7A":	2,
        "H7B":	2,
        "H7C":	2,
        "H7E":	2,
        "H7G":	2,
        "H7H":	2,
        "H7J":	2,
        "H7K":	2,
        "H7L":	2,
        "H7M":	2,
        "H7N":	2,
        "H7P":	2,
        "H7R":	2,
        "H7S":	2,
        "H7T":	2,
        "H7V":	2,
        "H7W":	2,
        "H7X":	2,
        "H7Y":	2,
        "H7Z":	6,
        "H8A":	6,
        "H8B":	6,
        "H8C":	6,
        "H8E":	6,
        "H8G":	6,
        "H8H":	6,
        "H8J":	6,
        "H8K":	6,
        "H8L":	6,
        "H8M":	6,
        "H8N":	1,
        "H8P":	1,
        "H8R":	1,
        "H8S":	1,
        "H8T":	1,
        "H8V":	6,
        "H8W":	6,
        "H8X":	6,
        "H8Y":	1,
        "H8Z":	1,
        "H9A":	1,
        "H9B":	1,
        "H9C":	2,
        "H9E":	2,
        "H9G":	1,
        "H9H":	1,
        "H9J":	1,
        "H9K":	1,
        "H9L":	6,
        "H9M":	6,
        "H9N":	6,
        "H9P":	1,
        "H9R":	1,
        "H9S":	1,
        "H9T":	1,
        "H9V":	1,
        "H9W":	1,
        "H9X":	2,
        "H9Y":	6,
        "H9Z":	6,
        "J1A":	7,
        "J1B":  6,
        "J1C":	7,
        "J1E":	7,
        "J1G":	7,
        "J1H":	7,
        "J1J":	7,
        "J1K":	7,
        "J1L":	7,
        "J1M":	7,
        "J1N":	7,
        "J1P":	6,
        "J1R":	7,
        "J1S":	7,
        "J1T":	7,
        "J1V":	6,
        "J1W":	6,
        "J1X":	7,
        "J1Y":	6,
        "J1Z":	7,
        "J2A":	7,
        "J2B":	7,
        "J2C":	7,
        "J2E":	7,
        "J2G":	7,
        "J2H":	7,
        "J2J":	7,
        "J2K":	7,
        "J2L":	7,
        "J2M":	7,
        "J2N":	7,
        "J2P":	6,
        "J2R":	7,
        "J2S":	7,
        "J2T":	7,
        "J2V":	6,
        "J2W":	4,
        "J2X":	4,
        "J2Y":	4,
        "J2Z":	6,
        "J3A":	4,
        "J3B":	4,
        "J3C":	6,
        "J3E":	3,
        "J3G":	4,
        "J3H":	4,
        "J3J":	6,
        "J3K":	6,
        "J3L":	3,
        "J3M":	3,
        "J3N":	3,
        "J3P":	7,
        "J3R":	7,
        "J3S":	6,
        "J3T":	7,
        "J3V":	3,
        "J3W":	6,
        "J3X":	4,
        "J3Y":	3,
        "J3Z":	3,
        "J4A":	6,
        "J4B":	3,
        "J4C":	6,
        "J4E":	6,
        "J4G":	3,
        "J4H":	3,
        "J4J":	3,
        "J4K":	3,
        "J4L":	3,
        "J4M":	3,
        "J4N":	3,
        "J4P":	3,
        "J4R":	3,
        "J4S":	3,
        "J4T":	3,
        "J4V":	3,
        "J4W":	3,
        "J4X":	3,
        "J4Y":	3,
        "J4Z":	3,
        "J5A":	3,
        "J5B":	3,
        "J5C":	3,
        "J5E":	6,
        "J5G":	6,
        "J5H":	6,
        "J5J":	7,
        "J5K":	7,
        "J5L":	7,
        "J5M":	7,
        "J5N":	6,
        "J5P":	6,
        "J5R":	3,
        "J5S":	6,
        "J5T":	7,
        "J5V":	7,
        "J5W":	7,
        "J5X":	7,
        "J5Y":	4,
        "J5Z":	4,
        "J6A":	4,
        "J6B":	6,
        "J6C":	6,
        "J6E":	7,
        "J6G":	6,
        "J6H":	6,
        "J6J":	3,
        "J6K":	3,
        "J6L":	6,
        "J6M":	6,
        "J6N":	3,
        "J6P":	6,
        "J6R":	3,
        "J6S":	3,
        "J6T":	3,
        "J6V":	4,
        "J6W":	4,
        "J6X":	4,
        "J6Y":	4,
        "J6Z":	4,
        "J7A":	4,
        "J7B":	4,
        "J7C":	4,
        "J7E":	4,
        "J7G":	4,
        "J7H":	4,
        "J7J":	4,
        "J7K":	5,
        "J7L":	5,
        "J7M":	4,
        "J7N":	4,
        "J7P":	4,
        "J7R":	4,
        "J7S":	6,
        "J7T":	4,
        "J7V":	4,
        "J7W":	4,
        "J7X":	3,
        "J7Y":	7,
        "J7Z":	7,
        "J8A":	7,
        "J8B":	7,
        "J8C":	7,
        "J8E":	7,
        "J8G":	7,
        "J8H":	7,
        "J8J":	6,
        "J8K":	6,
        "J8L":	7,
        "J8M":	7,
        "J8N":	7,
        "J8P":	7,
        "J8R":	7,
        "J8S":	6,
        "J8T":	7,
        "J8V":	7,
        "J8W":	6,
        "J8X":	7,
        "J8Y":	7,
        "J8Z":	7,
        "J9A":	7,
        "J9B":	7,
        "J9C":	6,
        "J9E":	7,
        "J9G":	6,
        "J9H":	7,
        "J9J":	7,
        "J9K":	6,
        "J9L":	7,
        "J9M":	6,
        "J9N":	6,
        "J9P":	7,
        "J9R":	6,
        "J9S":	6,
        "J9T":	7,
        "J9V":	7,
        "J9W":	6,
        "J9X":	7,
        "J9Y":	7,
        "J9Z":	7,
        "J0A":	7,
        "J0B":	7,
        "J0C":	7,
        "J0E":	7,
        "J0G":	7,
        "J0H":	7,
        "J0J":	7,
        "J0K":	7,
        "J0L":	7,
        "J0M":	7,
        "J0N":	7,
        "J0P":	2,
        "J0R":	7,
        "J0S":	7,
        "J0T":	7,
        "J0V":	7,
        "J0W":	7,
        "J0X":	7,
        "J0Y":	7,
        "J0Z":	7,
        "K1A":	7,
        "K1B":	7,
        "K1C":	7,
        "K1E":	7,
        "K1G":	7,
        "K1H":	7,
        "K1J":	7,
        "K1K":	7,
        "K1L":	7,
        "K1M":	7,
        "K1N":	7,
        "K1P":	7,
        "K1R":	7,
        "K1S":	7,
        "K1T":	7,
        "K1V":	7,
        "K1W":	7,
        "K1X":	7,
        "K1Y":	7,
        "K1Z":	7,
        "K2A":	7,
        "K2B":	7,
        "K2C":	7,
        "K2E":	7,
        "K2G":	7,
        "K2H":	7,
        "K2J":	7,
        "K2K":	7,
        "K2L":	7,
        "K2M":	7,
        "K2N":	6,
        "K2P":	7,
        "K2R":	7,
        "K2S":	7,
        "K2T":	7,
        "K2V":	7,
        "K2W":	7,
        "K2X":	6,
        "K2Y":	6,
        "K2Z":	6,
        "K4A":	7,
        "K4B":	7,
        "K4C":	7,
        "K4E":	6,
        "K4G":	6,
        "K4H":	6,
        "K4J":	6,
        "K4K":	7,
        "K4L":	6,
        "K4M":	7,
        "K4N":	6,
        "K4P":	7,
        "K4R":	7,
        "K4S":	6,
        "K4T":	6,
        "K4V":	6,
        "K4W":	6,
        "K4X":	6,
        "K4Y":	6,
        "K4Z":	6,
        "K6A":	7,
        "K6B":	6,
        "K6C":	6,
        "K6E":	6,
        "K6G":	6,
        "K6H":	7,
        "K6J":	7,
        "K6K":	7,
        "K6L":	6,
        "K6M":	6,
        "K6N":	6,
        "K6P":	6,
        "K6R":	6,
        "K6S":	6,
        "K6T":	7,
        "K6V":	7,
        "K6W":	6,
        "K6X":	6,
        "K6Y":	6,
        "K6Z":	6,
        "K7A":	7,
        "K7B":	6,
        "K7C":	7,
        "K7E":	6,
        "K7G":	7,
        "K7H":	7,
        "K7J":	6,
        "K7K":	7,
        "K7L":	7,
        "K7M":	7,
        "K7N":	7,
        "K7P":	7,
        "K7R":	7,
        "K7S":	7,
        "K7T":	6,
        "K7V":	7,
        "K7W":	6,
        "K7X":	6,
        "K7Y":	6,
        "K7Z":	6,
        "K8A":	7,
        "K8B":	7,
        "K8C":	6,
        "K8E":	6,
        "K8G":	6,
        "K8H":	7,
        "K8J":	6,
        "K8K":	6,
        "K8L":	6,
        "K8M":	6,
        "K8N":	7,
        "K8P":	7,
        "K8R":	7,
        "K8S":	6,
        "K8T":	6,
        "K8V":	7,
        "K8W":	6,
        "K8X":	6,
        "K8Y":	6,
        "K8Z":	6,
        "K9A":	7,
        "K9B":	6,
        "K9C":	6,
        "K9E":	6,
        "K9G":	6,
        "K9H":	7,
        "K9J":	7,
        "K9K":	7,
        "K9L":	7,
        "K9M":	6,
        "K9N":	6,
        "K9P":	6,
        "K9R":	6,
        "K9S":	6,
        "K9T":	6,
        "K9V":	7,
        "K9W":	6,
        "K9X":	6,
        "K9Y":	6,
        "K9Z":	6,
        "K0A":	7,
        "K0B":	7,
        "K0C":	7,
        "K0E":	7,
        "K0G":	7,
        "K0H":	7,
        "K0J":	7,
        "K0K":	7,
        "K0L":	7,
        "K0M":	7,
        "K0N":	6,
        "K0P":	6,
        "K0R":	6,
        "K0S":	6,
        "K0T":	6,
        "K0V":	6,
        "K0W":	6,
        "K0X":	6,
        "K0Y":	6,
        "K0Z":	6,
        "G0A":	7,
        "G0B":	7,
        "G0C":	7,
        "G0E":	7,
        "G0G":	7,
        "G0H":	7,
        "G0J":	7,
        "G0K":	7,
        "G0L":	7,
        "G0M":	7,
        "G0N":	7,
        "G0P":	7,
        "G0R":	7,
        "G0S":	7,
        "G0T":	7,
        "G0V":	7,
        "G0W":	7,
        "G0X":	7,
        "G0Y":	7,
        "G0Z":	7,
        "G1A":	7,
        "G1B":	7,
        "G1C":	7,
        "G1E":	7,
        "G1G":	7,
        "G1H":	7,
        "G1J":	7,
        "G1K":	7,
        "G1L":	7,
        "G1M":	7,
        "G1N":	7,
        "G1P":	7,
        "G1R":	7,
        "G1S":	7,
        "G1T":	7,
        "G1V":	7,
        "G1W":	7,
        "G1X":	7,
        "G1Y":	7,
        "G1Z":	6,
        "G2A":	7,
        "G2B":	7,
        "G2C":	7,
        "G2E":	7,
        "G2G":	7,
        "G2H":	6,
        "G2J":	7,
        "G2K":	7,
        "G2L":	7,
        "G2M":	7,
        "G2N":	7,
        "G2P":	6,
        "G2R":	6,
        "G2S":	6,
        "G2T":	6,
        "G2V":	6,
        "G2W":	6,
        "G2X":	6,
        "G2Y":	6,
        "G2Z":	6,
        "G3A":	7,
        "G3B":	7,
        "G3C":	7,
        "G3E":	7,
        "G3G":	7,
        "G3H":	7,
        "G3J":	7,
        "G3K":	7,
        "G3L":	7,
        "G3M":	7,
        "G3N":	7,
        "G3P":	6,
        "G3R":	6,
        "G3S":	7,
        "G3T":	6,
        "G3V":	6,
        "G3W":	6,
        "G3X":	6,
        "G3Y":	6,
        "G3Z":	7,
        "G4A":	7,
        "G4B":	6,
        "G4C":	6,
        "G4E":	6,
        "G4G":	6,
        "G4H":	6,
        "G4J":	6,
        "G4K":	6,
        "G4L":	6,
        "G4M":	6,
        "G4N":	6,
        "G4P":	6,
        "G4R":	7,
        "G4S":	7,
        "G4T":	7,
        "G4V":	7,
        "G4W":	7,
        "G4X":	7,
        "G4Y":	6,
        "G4Z":	7,
        "G5A":	7,
        "G5B":	7,
        "G5C":	7,
        "G5E":	6,
        "G5G":	6,
        "G5H":	7,
        "G5J":	7,
        "G5K":	6,
        "G5L":	7,
        "G5M":	7,
        "G5N":	7,
        "G5P":	6,
        "G5R":	7,
        "G5S":	6,
        "G5T":	7,
        "G5V":	7,
        "G5W":	6,
        "G5X":	7,
        "G5Y":	7,
        "G5Z":	7,
        "G6A":	7,
        "G6B":	7,
        "G6C":	7,
        "G6E":	7,
        "G6G":	7,
        "G6H":	7,
        "G6J":	7,
        "G6K":	7,
        "G6L":	7,
        "G6M":	6,
        "G6N":	6,
        "G6P":	7,
        "G6R":	7,
        "G6S":	7,
        "G6T":	7,
        "G6V":	7,
        "G6W":	7,
        "G6X":	7,
        "G6Y":	7,
        "G6Z":	7,
        "G7A":	7,
        "G7B":	7,
        "G7C":	6,
        "G7E":	6,
        "G7G":	7,
        "G7H":	7,
        "G7J":	7,
        "G7K":	7,
        "G7L":	6,
        "G7M":	6,
        "G7N":	7,
        "G7P":	7,
        "G7R":	6,
        "G7S":	7,
        "G7T":	7,
        "G7V":	6,
        "G7W":	6,
        "G7X":	7,
        "G7Y":	7,
        "G7Z":	7,
        "G8A":	7,
        "G8B":	7,
        "G8C":	7,
        "G8E":	7,
        "G8G":	7,
        "G8H":	7,
        "G8J":	7,
        "G8K":	7,
        "G8L":	7,
        "G8M":	7,
        "G8N":	7,
        "G8P":	7,
        "G8R":	6,
        "G8S":	6,
        "G8T":	7,
        "G8V":	7,
        "G8W":	7,
        "G8X":	6,
        "G8Y":	7,
        "G8Z":	7,
        "G9A":	7,
        "G9B":	7,
        "G9C":	7,
        "G9E":	6,
        "G9G":	6,
        "G9H":	7,
        "G9J":	6,
        "G9K":	6,
        "G9L":	6,
        "G9M":	6,
        "G9N":	7,
        "G9P":	7,
        "G9R":	7,
        "G9S":	6,
        "G9T":	7,
        "G9V":	6,
        "G9W":	6,
        "G9X":	7,
        "G9Y":	6,
        "G9Z":	6
      }
    },
    "YYC": {
      "disclaimer": `<p>This zone is calculated by 'CW' aka Chargeable Weight. Chargeable is whichever is larger of the gross weight and volume weight.</p><p>This zone is calculated in metric measuments exclusively.</p><p>For any shipping unit surpassing 2.44m  in lenght, 1.83m in width or 2m in height, please contact dap@etalogistics.ca for a personalized quote.</p><p>For any shipment of more than 10 pallets please contact dap@etalogistics.ca for a personalized quote.</p><p>Shipment must be palletized, skidded or crated. Loose carton shipments require personalized quotes.</p><p>Tailgate deliveries not available for more than 10 pallets, please contact dap@etalogistics.ca for requests that are outside of the norm or "out of zone"</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 75.00 USD / H charged in 15 min increments.</p><p>HAZMAT Shipments, CITES Shipments, and any shipments outside of "General Cargo" require personalized quote.</p><p>Downtown and central core of Calgary Shipments require personalized quotes. These are charged hourly.</p>`,
      "method": "weights",
      "perKilo": [
        { "min": 1, "max": 250, "price": 0 },
        { "min": 251, "max": 1000, "price": 0.1 },
        { "min": 1001, "max": 2000, "price": 0.08 },
        { "min": 2001, "max": 3000, "price": 0.07 },
        { "min": 3001, "max": 4000, "price": 0.06 },
        { "min": 4000, "max": 5000, "price": 0.05 },
      ],
      "fuelSurchage": 0.21,
      "carbonTax": 0.015,
      "COVIDAirportFees": 0,
      "terminalFees": 95,
      "handlingFees": 50,
      "zones": {
        1: {
          "rates": [
            { "min": 1, "max": 250, "price": 37.5 },
            { "min": 251, "max": 1000, "price": 37.5 },
            { "min": 1001, "max": 2000, "price": 37.5 },
            { "min": 2001, "max": 3000, "price": 37.5 },
            { "min": 3001, "max": 4000, "price": 37.5 },
            { "min": 4000, "max": 5000, "price": 37.5 },
          ]
        }
      },
      "postcodes": {
        "T0A": 7,
        "T0B": 7,
        "T0C": 7,
        "T0E": 7,
        "T0G": 7,
        "T0H": 7,
        "T0J": 7,
        "T0K": 7,
        "T0L": 7,
        "T0M": 7,
        "T0P": 7,
        "T0V": 7,
        "T1A": 7,
        "T1B": 7,
        "T1C": 7,
        "T1G": 7,
        "T1H": 7,
        "T1J": 7,
        "T1K": 7,
        "T1L": 7,
        "T1M": 7,
        "T1P": 7,
        "T1R": 7,
        "T1V": 7,
        "T1W": 7,
        "T2G": 7,
        "T2P": 7,
        "T2R": 7,
        "T4C": 7,
        "T4E": 7,
        "T4G": 7,
        "T4H": 7,
        "T4J": 7,
        "T4L": 7,
        "T4M": 7,
        "T4N": 7,
        "T4P": 7,
        "T4R": 7,
        "T4S": 7,
        "T4T": 7,
        "T4V": 7,
        "T7A": 7,
        "T7E": 7,
        "T7N": 7,
        "T7P": 7,
        "T7S": 7,
        "T7V": 7,
        "T7X": 7,
        "T7Y": 7,
        "T7Z": 7,
        "T8A": 7,
        "T8B": 7,
        "T8C": 7,
        "T8E": 7,
        "T8G": 7,
        "T8H": 7,
        "T8L": 7,
        "T8N": 7,
        "T8R": 7,
        "T8S": 7,
        "T8T": 7,
        "T8V": 7,
        "T8W": 7,
        "T8X": 7,
        "T9A": 7,
        "T9C": 7,
        "T9G": 7,
        "T9H": 7,
        "T9J": 7,
        "T9K": 7,
        "T9M": 7,
        "T9N": 7,
        "T9S": 7,
        "T9V": 7,
        "T9W": 7,
        "T9X": 7,
        "T0D": 6,
        "T0N": 6,
        "T0R": 6,
        "T0S": 6,
        "T0T": 6,
        "T0W": 6,
        "T0X": 6,
        "T0Y": 6,
        "T0Z": 6,
        "T1E": 6,
        "T1N": 6,
        "T1T": 6,
        "T3V": 6,
        "T3W": 6,
        "T3X": 6,
        "T3Y": 6,
        "T4K": 6,
        "T4W": 6,
        "T4Y": 6,
        "T4Z": 6,
        "T6Z": 6,
        "T7B": 6,
        "T7C": 6,
        "T7G": 6,
        "T7H": 6,
        "T7J": 6,
        "T7K": 6,
        "T7L": 6,
        "T7M": 6,
        "T7R": 6,
        "T7T": 6,
        "T7W": 6,
        "T8J": 6,
        "T8K": 6,
        "T8M": 6,
        "T8P": 6,
        "T8Y": 6,
        "T8Z": 6,
        "T9B": 6,
        "T9L": 6,
        "T9P": 6,
        "T9R": 6,
        "T9T": 6,
        "T9Y": 6,
        "T9Z": 6,
        "T9E": 7,
        "T5A": 7,
        "T5B": 7,
        "T5C": 7,
        "T5E": 7,
        "T5G": 7,
        "T5H": 7,
        "T5J": 7,
        "T5K": 7,
        "T5L": 7,
        "T5M": 7,
        "T5N": 7,
        "T5P": 7,
        "T5R": 7,
        "T5S": 7,
        "T5T": 7,
        "T5V": 7,
        "T5W": 7,
        "T5X": 7,
        "T5Y": 7,
        "T5Z": 7,
        "T6A": 7,
        "T6B": 7,
        "T6C": 7,
        "T6E": 7,
        "T6G": 7,
        "T6H": 7,
        "T6J": 7,
        "T6K": 7,
        "T6L": 7,
        "T6M": 7,
        "T6N": 7,
        "T6P": 7,
        "T6R": 7,
        "T6S": 7,
        "T6T": 7,
        "T6V": 7,
        "T6W": 7,
        "T6X": 7,
        "T6Y": 7,
        "T4X": 7,
        "T1S": 1,
        "T1X": 1,
        "T1Y": 1,
        "T1Z": 1,
        "T2A": 1,
        "T2B": 1,
        "T2C": 1,
        "T2E": 1,
        "T2H": 1,
        "T2J": 1,
        "T2K": 1,
        "T2L": 1,
        "T2M": 1,
        "T2N": 1,
        "T2S": 1,
        "T2T": 1,
        "T2V": 1,
        "T2W": 1,
        "T2X": 1,
        "T2Y": 1,
        "T2Z": 1,
        "T3A": 1,
        "T3B": 1,
        "T3C": 1,
        "T3E": 1,
        "T3G": 1,
        "T3H": 1,
        "T3J": 1,
        "T3K": 1,
        "T3L": 1,
        "T3M": 1,
        "T3N": 1,
        "T3P": 1,
        "T3R": 1,
        "T3S": 1,
        "T3T": 1,
        "T3Z": 1,
        "T4A": 1,
        "T4B": 1
      }
    },
    "YEG": {
      "disclaimer": `<p>This zone is calculated by "CW" aka Chargeable Weight. Chargeable is whichever is larger of the gross weight and volume weight.</p><p>This zone is calculated in metric measuments exclusively.</p><p>For any shipping unit surpassing 2.44m  in lenght, 1.83m in width or 2m in height, please contact dap@etalogistics.ca for a personalized quote.</p><p>For any shipment of more than 10 pallets or over 4535.5 kg  please contact dap@etalogistics.ca for a personalized quote.</p><p>Shipment must be palletized, skidded or crated. Loose carton shipments require personalized quotes. </p><p>Tailgate deliveries not available for more than 10 pallets, please contact dap@etalogistics.ca for requests that are outside of the norm or "out of zone"</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 75.00 USD / H charged in 15 min increments.</p><p>HAZMAT Shipments, CITES Shipments, and any shipments outside of "General Cargo" require personalized quote.</p>`,
      "method": "weights",
      "perKilo": [],
      "fuelSurchage": 0.21,
      "carbonTax": 0.015,
      "COVIDAirportFees": 0,
      "terminalFees": 95,
      "handlingFees": 50,
      "zones": {
        1: {
          "rates": [
            { "min": 1, "max": 679, "price": 78 },
            { "min": 680, "max": 906, "price": 84 },
            { "min": 907, "max": 1133, "price": 105 },
            { "min": 1134, "max": 1360, "price": 125 },
            { "min": 1361, "max": 1813, "price": 157 },
            { "min": 1814, "max": 2267, "price": 178 },
            { "min": 2268, "max": 3174, "price": 199 },
            { "min": 3175, "max": 4081, "price": 220 },
            { "min": 4082, "max": 4535.5, "price": 250 },
          ]
        }
      },
      "postcodes": {
        "T0A":	7,
        "T0B":	7,
        "T0C":	7,
        "T0D":	6,
        "T0E":	7,
        "T0G":	7,
        "T0H":	7,
        "T0J":	7,
        "T0K":	7,
        "T0L":	7,
        "T0M":	7,
        "T0N":	6,
        "T0P":	7,
        "T0R":	6,
        "T0S":	6,
        "T0T":	6,
        "T0V":	7,
        "T0W":	6,
        "T0X":	6,
        "T0Y":	6,
        "T0Z":	6,
        "T1A":	7,
        "T1B":	7,
        "T1C":	7,
        "T1E":	6,
        "T1G":	7,
        "T1H":	7,
        "T1J":	7,
        "T1K":	7,
        "T1L":	7,
        "T1M":	7,
        "T1N":	6,
        "T1P":	7,
        "T1R":	7,
        "T1S":	7,
        "T1T":	6,
        "T1V":	7,
        "T1W":	7,
        "T1X":	7,
        "T1Y":	7,
        "T1Z":	7,
        "T2A":	7,
        "T2B":	7,
        "T2C":	7,
        "T2E":	7,
        "T2G":	7,
        "T2H":	7,
        "T2J":	7,
        "T2K":	7,
        "T2L":	7,
        "T2M":	7,
        "T2N":	7,
        "T2P":	7,
        "T2R":	7,
        "T2S":	7,
        "T2T":	7,
        "T2V":	7,
        "T2W":	7,
        "T2X":	7,
        "T2Y":	7,
        "T2Z":	7,
        "T3A":	7,
        "T3B":	7,
        "T3C":	7,
        "T3E":	7,
        "T3G":	7,
        "T3H":	7,
        "T3J":	7,
        "T3K":	7,
        "T3L":	7,
        "T3M":	7,
        "T3N":	7,
        "T3P":	7,
        "T3R":	7,
        "T3S":	7,
        "T3T":	7,
        "T3V":	6,
        "T3W":	6,
        "T3X":	6,
        "T3Y":	6,
        "T3Z":	7,
        "T4A":	7,
        "T4B":	7,
        "T4C":	7,
        "T4E":	7,
        "T4G":	7,
        "T4H":	7,
        "T4J":	7,
        "T4K":	6,
        "T4L":	7,
        "T4M":	7,
        "T4N":	7,
        "T4P":	7,
        "T4R":	7,
        "T4S":	7,
        "T4T":	7,
        "T4V":	7,
        "T4W":	6,
        "T4X":	1,
        "T4Y":	6,
        "T4Z":	6,
        "T5A":	1,
        "T5B":	1,
        "T5C":	1,
        "T5E":	1,
        "T5G":	1,
        "T5H":	1,
        "T5J":	1,
        "T5K":	1,
        "T5L":	1,
        "T5M":	1,
        "T5N":	1,
        "T5P":	1,
        "T5R":	1,
        "T5S":	1,
        "T5T":	1,
        "T5V":	1,
        "T5W":	1,
        "T5X":	1,
        "T5Y":	1,
        "T5Z":	1,
        "T6A":	1,
        "T6B":	1,
        "T6C":	1,
        "T6E":	1,
        "T6G":	1,
        "T6H":	1,
        "T6J":	1,
        "T6K":	1,
        "T6L":	1,
        "T6M":	1,
        "T6N":	1,
        "T6P":	1,
        "T6R":	1,
        "T6S":	1,
        "T6T":	1,
        "T6V":	1,
        "T6W":	1,
        "T6X":	1,
        "T6Y":	1,
        "T6Z":	6,
        "T7A":	7,
        "T7B":	6,
        "T7C":	6,
        "T7E":	7,
        "T7G":	6,
        "T7H":	6,
        "T7J":	6,
        "T7K":	6,
        "T7L":	6,
        "T7M":	6,
        "T7N":	7,
        "T7P":	7,
        "T7R":	6,
        "T7S":	7,
        "T7T":	6,
        "T7V":	7,
        "T7W":	6,
        "T7X":	7,
        "T7Y":	7,
        "T7Z":	7,
        "T8A":	7,
        "T8B":	7,
        "T8C":	7,
        "T8E":	7,
        "T8G":	7,
        "T8H":	7,
        "T8J":	6,
        "T8K":	6,
        "T8L":	7,
        "T8M":	6,
        "T8N":	7,
        "T8P":	6,
        "T8R":	7,
        "T8S":	7,
        "T8T":	7,
        "T8V":	7,
        "T8W":	7,
        "T8X":	7,
        "T8Y":	6,
        "T8Z":	6,
        "T9A":	7,
        "T9B":	6,
        "T9C":	7,
        "T9E":	1,
        "T9G":	7,
        "T9H":	7,
        "T9J":	7,
        "T9K":	7,
        "T9L":	6,
        "T9M":	7,
        "T9N":	7,
        "T9P":	6,
        "T9R":	6,
        "T9S":	7,
        "T9T":	6,
        "T9V":	7,
        "T9W":	7,
        "T9X":	7,
        "T9Y":	6,
        "T9Z":	6,
      }
    },
    "YHZ": {
      "disclaimer": `<p>This zone is calculated by "CW" aka Chargeable Weight. Chargeable is whichever is larger of the gross weight and volume weight.</p><p>This zone is calculated in metric measuments exclusively.</p><p>For any shipping unit surpassing 1.83m  in lenght, 1.83m in width or 2m in height, please contact dap@etalogistics.ca for a personalized quote.</p><p>For any shipment of more than 10 standard pallets, or of more than 20 CBM please contact dap@etalogistics.ca for a personalized quote.</p><p>Shipment must be palletized, skidded or crated. Loose carton shipments require personalized quotes.</p><p>Tailgate deliveries not available for more than 10 pallets, please contact dap@etalogistics.ca for requests that are outside of the norm or "out of zone"</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 90.00 USD / H charged in 15 min increments.</p><p>HAZMAT Shipments, CITES Shipments, and any shipments outside of "General Cargo" require personalized quote.</p>`,
      "method": "weights",
      "perKilo": [],
      "fuelSurchage": 0.01,
      "carbonTax": 0.015,
      "terminalFees": 95,
      "handlingFees": 50,
      "COVIDAirportFees": 45,
      "zones": {
        1: {
          "rates": [
            { "min": 0, "max": 100000, "price": 112.5 },
          ]
        },
        2: {
          "rates": [
            { "min": 0, "max": 100000, "price": 189 },
          ]
        }
      },
      "postcodes": {
        "B0A":	3,
        "B0B":	3,
        "B0C":	4,
        "B0E":	4,
        "B0G":	4,
        "B0H":	4,
        "B0J":	4,
        "B0K":	4,
        "B0L":	4,
        "B0M":	4,
        "B0N":	4,
        "B0P":	4,
        "B0R":	4,
        "B0S":	4,
        "B0T":	4,
        "B0V":	4,
        "B0W":	4,
        "B0X":	3,
        "B0Y":	3,
        "B0Z":	3,
        "B1A":	4,
        "B1B":	4,
        "B1C":	4,
        "B1E":	4,
        "B1G":	4,
        "B1H":	4,
        "B1J":	4,
        "B1K":	4,
        "B1L":	4,
        "B1M":	4,
        "B1N":	4,
        "B1P":	4,
        "B1R":	4,
        "B1S":	4,
        "B1T":	4,
        "B1V":	4,
        "B1W":	4,
        "B1X":	4,
        "B1Y":	4,
        "B1Z":	3,
        "B2A":	4,
        "B2B":	3,
        "B2C":	4,
        "B2E":	4,
        "B2G":	4,
        "B2H":	4,
        "B2J":	4,
        "B2K":	3,
        "B2L":	3,
        "B2M":	3,
        "B2N":	4,
        "B2P":	3,
        "B2R":	2,
        "B2S":	2,
        "B2T":	2,
        "B2V":	2,
        "B2W":	1,
        "B2X":	1,
        "B2Y":	1,
        "B2Z":	1,
        "B3A":	1,
        "B3B":	1,
        "B3C":	3,
        "B3E":	4,
        "B3G":	4,
        "B3H":	1,
        "B3J":	1,
        "B3K":	1,
        "B3L":	1,
        "B3M":	1,
        "B3N":	1,
        "B3P":	1,
        "B3R":	1,
        "B3S":	1,
        "B3T":	4,
        "B3V":	4,
        "B3W":	3,
        "B3X":	3,
        "B3Y":	3,
        "B3Z":	4,
        "B4A":	2,
        "B4B":	2,
        "B4C":	2,
        "B4E":	2,
        "B4G":	2,
        "B4H":	4,
        "B4J":	3,
        "B4K":	3,
        "B4L":	3,
        "B4M":	3,
        "B4N":	4,
        "B4P":	4,
        "B4R":	4,
        "B4S":	3,
        "B4T":	3,
        "B4V":	4,
        "B4W":	3,
        "B4X":	3,
        "B4Y":	3,
        "B4Z":	3,
        "B5A":	4,
        "B5B":	3,
        "B5C":	3,
        "B5E":	3,
        "B5G":	3,
        "B5H":	3,
        "B5J":	3,
        "B5K":	3,
        "B5L":	3,
        "B5M":	3,
        "B5N":	3,
        "B5P":	3,
        "B5R":	3,
        "B5S":	3,
        "B5T":	3,
        "B5V":	3,
        "B5W":	3,
        "B5X":	3,
        "B5Y":	3,
        "B5Z":	3,
        "B6A":	3,
        "B6B":	3,
        "B6C":	3,
        "B6E":	3,
        "B6G":	3,
        "B6H":	3,
        "B6J":	3,
        "B6K":	3,
        "B6L":	4,
        "B6M":	3,
        "B6N":	3,
        "B6P":	3,
        "B6R":	3,
        "B6S":	3,
        "B6T":	3,
        "B6V":	3,
        "B6W":	3,
        "B6X":	3,
        "B6Y":	3,
        "B6Z":	3,
        "B7A":	4,
        "B7B":	3,
        "B7C":	3,
        "B7E":	3,
        "B7G":	3,
        "B7H":	3,
        "B7J":	3,
        "B7K":	3,
        "B7L":	3,
        "B7M":	3,
        "B7N":	3,
        "B7P":	3,
        "B7R":	3,
        "B7S":	3,
        "B7T":	3,
        "B7V":	3,
        "B7W":	3,
        "B7X":	3,
        "B7Y":	3,
        "B7Z":	3,
      }
    },
    "YVR": {
      "disclaimer": `<p>This zone is calculated by "CW" aka Chargeable Weight. Chargeable is whichever is larger of the gross weight and volume weight.</p><p>This zone is calculated in metric measuments exclusively.</p><p>For any shipping unit surpassing 2.44m  in lenght, 1.83m in width or 2m in height, please contact dap@etalogistics.ca for a personalized quote.</p><p>For any shipment of more than 10 pallets please contact dap@etalogistics.ca for a personalized quote.</p><p>Shipment must be palletized, skidded or crated. Loose carton shipments require personalized quotes.</p><p>Tailgate deliveries not available for more than 10 pallets, please contact dap@etalogistics.ca for requests that are outside of the norm or "out of zone".</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 75.00 USD / H charged in 15 min increments.</p><p>HAZMAT Shipments, CITES Shipments, and any shipments outside of "General Cargo" require personalized quote.</p>`,
      "method": "weights",
      "perKilo": [],
      "fuelSurchage": 0.05,
      "carbonTax": 0,
      "terminalFees": 95,
      "handlingFees": 50,
      "COVIDAirportFees": 0,
      "zones": {
        1: {
          "rates": [
            { "min": 0, "max": 44.91, "price": 63.01 },
            { "min": 44.92, "max": 90.26, "price": 64.55 },
            { "min": 90.27, "max": 135.62, "price": 66.07 },
            { "min": 135.63, "max": 226.34, "price": 70.75 },
            { "min": 226.35, "max": 317.06, "price": 71.99 },
            { "min": 317.07, "max": 453.14, "price": 75.96 },
            { "min": 453.15, "max": 543.86, "price": 88.21 },
            { "min": 543.87, "max": 679.93, "price": 92.74 },
            { "min": 679.94, "max": 770.65, "price": 95.8 },
            { "min": 770.66, "max": 906.73, "price": 100.46 },
            { "min": 906.74, "max": 1133.53, "price": 104.01 },
            { "min": 1133.54, "max": 1360.32, "price": 108.94 },
            { "min": 1361.33, "max": 1587.12, "price": 114.17 },
            { "min": 1587.13, "max": 1813.91, "price": 118.76 },
            { "min": 1813.92, "max": 2040.71, "price": 124.25 },
            { "min": 2040.72, "max": 2267.96, "price": 129.54 }
          ]
        },
        2: {
          "rates": [
            { "min": 0, "max": 44.91, "price": 68.71 },
            { "min": 44.92, "max": 90.26, "price": 71.7 },
            { "min": 90.27, "max": 135.62, "price": 73.02 },
            { "min": 135.63, "max": 226.34, "price": 76.3 },
            { "min": 226.35, "max": 317.06, "price": 80.07 },
            { "min": 317.07, "max": 453.14, "price": 83.32 },
            { "min": 453.15, "max": 543.86, "price": 97.11 },
            { "min": 543.87, "max": 679.93, "price": 100.17 },
            { "min": 679.94, "max": 770.65, "price": 103.23 },
            { "min": 770.66, "max": 906.73, "price": 108.52 },
            { "min": 906.74, "max": 1133.53, "price": 112.42 },
            { "min": 1133.54, "max": 1360.32, "price": 118.19 },
            { "min": 1361.33, "max": 1587.12, "price": 123.69 },
            { "min": 1587.13, "max": 1813.91, "price": 129.14 },
            { "min": 1813.92, "max": 2040.71, "price": 134.62 },
            { "min": 2040.72, "max": 2267.96, "price": 141.93 }
          ]
        },
        3: {
          "rates": [
            { "min": 0, "max": 44.91, "price": 75.17 },
            { "min": 44.92, "max": 90.26, "price": 78.3 },
            { "min": 90.27, "max": 135.62, "price": 81.51 },
            { "min": 135.63, "max": 226.34, "price": 83.38 },
            { "min": 226.35, "max": 317.06, "price": 86.59 },
            { "min": 317.07, "max": 453.14, "price": 91.81 },
            { "min": 453.15, "max": 543.86, "price": 108.58 },
            { "min": 543.87, "max": 679.93, "price": 111.57 },
            { "min": 679.94, "max": 770.65, "price": 115.19 },
            { "min": 770.66, "max": 906.73, "price": 118.74 },
            { "min": 906.74, "max": 1133.53, "price": 123.82 },
            { "min": 1133.54, "max": 1360.32, "price": 128.69 },
            { "min": 1361.33, "max": 1587.12, "price": 135.72 },
            { "min": 1587.13, "max": 1813.91, "price": 144.14 },
            { "min": 1813.92, "max": 2040.71, "price": 151.66 },
            { "min": 2040.72, "max": 2267.96, "price": 159.31 }
          ]
        },
        4: {
          "rates": [
            { "min": 0, "max": 44.91, "price": 83.86 },
            { "min": 44.92, "max": 90.26, "price": 86.51 },
            { "min": 90.27, "max": 135.62, "price": 89.15 },
            { "min": 135.63, "max": 226.34, "price": 91.59 },
            { "min": 226.35, "max": 317.06, "price": 96.05 },
            { "min": 317.07, "max": 453.14, "price": 101.67 },
            { "min": 453.15, "max": 543.86, "price": 117.82 },
            { "min": 543.87, "max": 679.93, "price": 122.83 },
            { "min": 679.94, "max": 770.65, "price": 125.55 },
            { "min": 770.66, "max": 906.73, "price": 127.91 },
            { "min": 906.74, "max": 1133.53, "price": 132.09 },
            { "min": 1133.54, "max": 1360.32, "price": 138.92 },
            { "min": 1361.33, "max": 1587.12, "price": 147.47 },
            { "min": 1587.13, "max": 1813.91, "price": 156.66 },
            { "min": 1813.92, "max": 2040.71, "price": 167.52 },
            { "min": 2040.72, "max": 2267.96, "price": 175.17 }
          ]
        },
        5: {
          "rates": [
            { "min": 0, "max": 44.91, "price": 88.94 },
            { "min": 44.92, "max": 90.26, "price": 94.93 },
            { "min": 90.27, "max": 135.62, "price": 100 },
            { "min": 135.63, "max": 226.34, "price": 104.05 },
            { "min": 226.35, "max": 317.06, "price": 108.35 },
            { "min": 317.07, "max": 453.14, "price": 110.93 },
            { "min": 453.15, "max": 543.86, "price": 124.57 },
            { "min": 543.87, "max": 679.93, "price": 136.62 },
            { "min": 679.94, "max": 770.65, "price": 145.80 },
            { "min": 770.66, "max": 906.73, "price": 151.50 },
            { "min": 906.74, "max": 1133.53, "price": 158.33 },
            { "min": 1133.54, "max": 1360.32, "price": 174.40 },
            { "min": 1361.33, "max": 1587.12, "price": 182.68 },
            { "min": 1587.13, "max": 1813.91, "price": 199.60 },
            { "min": 1813.92, "max": 2040.71, "price": 208.23 },
            { "min": 2040.72, "max": 2267.96, "price": 216.44 }
          ]
        }
      },
      "postcodes": {
        "V0A":	7,
        "V0B":	7,
        "V0C":	7,
        "V0E":	7,
        "V0G":	7,
        "V0H":	7,
        "V0J":	7,
        "V0K":	7,
        "V0L":	7,
        "V0M":	7,
        "V0N":	7,
        "V0P":	7,
        "V0R":	7,
        "V0S":	7,
        "V0T":	7,
        "V0V":	7,
        "V0W":	7,
        "V0X":	7,
        "V0Y":	6,
        "V0Z":	6,
        "V1A":	7,
        "V1B":	7,
        "V1C":	7,
        "V1E":	7,
        "V1G":	7,
        "V1H":	7,
        "V1J":	7,
        "V1K":	7,
        "V1L":	7,
        "V1M":	7,
        "V1N":	7,
        "V1P":	7,
        "V1R":	7,
        "V1S":	7,
        "V1T":	7,
        "V1V":	7,
        "V1W":	7,
        "V1X":	7,
        "V1Y":	7,
        "V1Z":	7,
        "V2A":	7,
        "V2B":	7,
        "V2C":	7,
        "V2E":	7,
        "V2G":	7,
        "V2H":	7,
        "V2J":	7,
        "V2K":	7,
        "V2L":	7,
        "V2M":	7,
        "V2N":	7,
        "V2P":	7,
        "V2R":	7,
        "V2S":	7,
        "V2T":	7,
        "V2V":	7,
        "V2W":	7,
        "V2X":	7,
        "V2Y":	5,
        "V2Z":	5,
        "V3A":	5,
        "V3B":	5,
        "V3C":	5,
        "V3E":	4,
        "V3G":	7,
        "V3H":	5,
        "V3J":	3,
        "V3K":	4,
        "V3L":	3,
        "V3M":	3,
        "V3N":	3,
        "V3P":	6,
        "V3R":	4,
        "V3S":	5,
        "V3T":	4,
        "V3V":	4,
        "V3W":	4,
        "V3X":	4,
        "V3Y":	7,
        "V3Z":	5,
        "V4A":	4,
        "V4B":	5,
        "V4C":	4,
        "V4E":	4,
        "V4G":	4,
        "V4H":	6,
        "V4J":	6,
        "V4K":	4,
        "V4L":	5,
        "V4M":	5,
        "V4N":	5,
        "V4P":	4,
        "V4R":	7,
        "V4S":	7,
        "V4T":	7,
        "V4V":	7,
        "V4W":	5,
        "V4X":	7,
        "V4Y":	6,
        "V4Z":	7,
        "V5A":	3,
        "V5B":	3,
        "V5C":	3,
        "V5E":	3,
        "V5G":	3,
        "V5H":	3,
        "V5J":	3,
        "V5K":	2,
        "V5L":	2,
        "V5M":	2,
        "V5N":	2,
        "V5P":	2,
        "V5R":	2,
        "V5S":	2,
        "V5T":	2,
        "V5V":	2,
        "V5W":	2,
        "V5X":	2,
        "V5Y":	2,
        "V5Z":	2,
        "V6A":	2,
        "V6B":	2,
        "V6C":	2,
        "V6E":	2,
        "V6G":	2,
        "V6H":	2,
        "V6J":	2,
        "V6K":	2,
        "V6L":	2,
        "V6M":	2,
        "V6N":	2,
        "V6P":	2,
        "V6R":	2,
        "V6S":	3,
        "V6T":	3,
        "V6V":	2,
        "V6W":	2,
        "V6X":	1,
        "V6Y":	1,
        "V6Z":	2,
        "V7A":	1,
        "V7B":	1,
        "V7C":	1,
        "V7E":	1,
        "V7G":	5,
        "V7H":	4,
        "V7J":	4,
        "V7K":	4,
        "V7L":	4,
        "V7M":	4,
        "V7N":	4,
        "V7P":	4,
        "V7R":	4,
        "V7S":	5,
        "V7T":	5,
        "V7V":	5,
        "V7W":	5,
        "V7X":	2,
        "V7Y":	2,
        "V7Z":	7,
        "V8A":	7,
        "V8B":	7,
        "V8C":	7,
        "V8E":	7,
        "V8G":	7,
        "V8H":	6,
        "V8J":	6,
        "V8K":	6,
        "V8L":	6,
        "V8M":	6,
        "V8N":	6,
        "V8P":	6,
        "V8R":	6,
        "V8S":	6,
        "V8T":	6,
        "V8V":	6,
        "V8W":	6,
        "V8X":	6,
        "V8Y":	6,
        "V8Z":	6,
        "V9A":	6,
        "V9B":	6,
        "V9C":	6,
        "V9E":	6,
        "V9G":	6,
        "V9H":	6,
        "V9J":	6,
        "V9K":	6,
        "V9L":	6,
        "V9M":	6,
        "V9N":	6,
        "V9P":	6,
        "V9R":	6,
        "V9S":	6,
        "V9T":	6,
        "V9V":	6,
        "V9W":	6,
        "V9X":	6,
        "V9Z":	6,
      }
    },
    "YYZ": {
      "disclaimer": `<p>This zone is calculated by "CW" aka Chargeable Weight. Chargeable is whichever is larger of the gross weight and volume weight.</p><p>This zone is calculated in metric measuments exclusively.</p><p>For any shipping unit surpassing 2.44m  in lenght, 1.83m in width or 2m in height, please contact dap@etalogistics.ca for a personalized quote.</p><p>For any shipment of more than 10 pallets please contact dap@etalogistics.ca for a personalized quote.</p><p>Shipment must be palletized, skidded or crated. Loose carton shipments require personalized quotes. </p><p>Tailgate deliveries not available for more than 10 pallets, please contact dap@etalogistics.ca for requests that are outside of the norm or "out of zone"</p><p>Tailgate deliveries: Max weight is 1500 lbs // 680 kg ||| Max dimensions 72" x 48" x 79" // 183 x 122 x 200 cm per unit</p><p>Pick-ups and deliveries are alocated 30 mins each, anything above and beyond is charged 75.00 USD / H charged in 15 min increments.</p><p>HAZMAT Shipments, CITES Shipments, and any shipments outside of "General Cargo" require personalized quote.</p>`,
      "method": "weights",
      "perKilo": [],
      "fuelSurchage": 0.18,
      "carbonTax": 0,
      "terminalFees": 95,
      "handlingFees": 50,
      "COVIDAirportFees": 0,
      "postcodes": {
        "K0A":	7,
        "K0B":	7,
        "K0C":	7,
        "K0E":	7,
        "K0G":	7,
        "K0H":	7,
        "K0J":	7,
        "K0K":	7,
        "K0L":	7,
        "K0M":	7,
        "K0N":	6,
        "K0P":	6,
        "K0R":	6,
        "K0S":	6,
        "K0T":	6,
        "K0V":	6,
        "K0W":	6,
        "K0X":	6,
        "K0Y":	6,
        "K0Z":	6,
        "K1A":	7,
        "K1B":	7,
        "K1C":	7,
        "K1E":	7,
        "K1G":	7,
        "K1H":	7,
        "K1J":	7,
        "K1K":	7,
        "K1L":	7,
        "K1M":	7,
        "K1N":	7,
        "K1P":	7,
        "K1R":	7,
        "K1S":	7,
        "K1T":	7,
        "K1V":	7,
        "K1W":	7,
        "K1X":	7,
        "K1Y":	7,
        "K1Z":	7,
        "K2A":	7,
        "K2B":	7,
        "K2C":	7,
        "K2E":	7,
        "K2G":	7,
        "K2H":	7,
        "K2J":	7,
        "K2K":	7,
        "K2L":	7,
        "K2M":	7,
        "K2N":	6,
        "K2P":	7,
        "K2R":	7,
        "K2S":	7,
        "K2T":	7,
        "K2V":	7,
        "K2W":	7,
        "K2X":	6,
        "K2Y":	6,
        "K2Z":	6,
        "K4A":	7,
        "K4B":	7,
        "K4C":	7,
        "K4E":	6,
        "K4G":	6,
        "K4H":	6,
        "K4J":	6,
        "K4K":	7,
        "K4L":	6,
        "K4M":	7,
        "K4N":	6,
        "K4P":	7,
        "K4R":	7,
        "K4S":	6,
        "K4T":	6,
        "K4V":	6,
        "K4W":	6,
        "K4X":	6,
        "K4Y":	6,
        "K4Z":	6,
        "K6A":	7,
        "K6B":	6,
        "K6C":	6,
        "K6E":	6,
        "K6G":	6,
        "K6H":	7,
        "K6J":	7,
        "K6K":	7,
        "K6L":	6,
        "K6M":	6,
        "K6N":	6,
        "K6P":	6,
        "K6R":	6,
        "K6S":	6,
        "K6T":	7,
        "K6V":	7,
        "K6W":	6,
        "K6X":	6,
        "K6Y":	6,
        "K6Z":	6,
        "K7A":	7,
        "K7B":	6,
        "K7C":	7,
        "K7E":	6,
        "K7G":	7,
        "K7H":	7,
        "K7J":	6,
        "K7K":	7,
        "K7L":	7,
        "K7M":	7,
        "K7N":	7,
        "K7P":	7,
        "K7R":	7,
        "K7S":	7,
        "K7T":	6,
        "K7V":	7,
        "K7W":	6,
        "K7X":	6,
        "K7Y":	6,
        "K7Z":	6,
        "K8A":	7,
        "K8B":	7,
        "K8C":	6,
        "K8E":	6,
        "K8G":	6,
        "K8H":	7,
        "K8J":	6,
        "K8K":	6,
        "K8L":	6,
        "K8M":	6,
        "K8N":	7,
        "K8P":	7,
        "K8R":	7,
        "K8S":	6,
        "K8T":	6,
        "K8V":	7,
        "K8W":	6,
        "K8X":	6,
        "K8Y":	6,
        "K8Z":	6,
        "K9A":	7,
        "K9B":	6,
        "K9C":	6,
        "K9E":	6,
        "K9G":	6,
        "K9H":	7,
        "K9J":	7,
        "K9K":	7,
        "K9L":	7,
        "K9M":	6,
        "K9N":	6,
        "K9P":	6,
        "K9R":	6,
        "K9S":	6,
        "K9T":	6,
        "K9V":	7,
        "K9W":	6,
        "K9X":	6,
        "K9Y":	6,
        "K9Z":	6,
        "L0A":	7,
        "L0B":	5,
        "L0C":	7,
        "L0E":	7,
        "L0G":	5,
        "L0H":	7,
        "L0J":	7,
        "L0K":	7,
        "L0L":	7,
        "L0M":	7,
        "L0N":	7,
        "L0P":	7,
        "L0R":	5,
        "L0S":	6,
        "L0T":	6,
        "L0V":	6,
        "L0W":	6,
        "L0X":	6,
        "L0Y":	6,
        "L0Z":	6,
        "L1A":	7,
        "L1B":	7,
        "L1C":	5,
        "L1E":	7,
        "L1G":	5,
        "L1H":	5,
        "L1J":	5,
        "L1K":	5,
        "L1L":	5,
        "L1M":	4,
        "L1N":	4,
        "L1P":	4,
        "L1R":	4,
        "L1S":	3,
        "L1T":	3,
        "L1V":	3,
        "L1W":	3,
        "L1X":	3,
        "L1Y":	3,
        "L1Z":	3,
        "L2A":	7,
        "L2B":	6,
        "L2C":	6,
        "L2E":	7,
        "L2G":	7,
        "L2H":	7,
        "L2J":	7,
        "L2K":	6,
        "L2L":	6,
        "L2M":	5,
        "L2N":	5,
        "L2P":	5,
        "L2R":	5,
        "L2S":	5,
        "L2T":	5,
        "L2V":	5,
        "L2W":	5,
        "L2X":	6,
        "L2Y":	6,
        "L2Z":	6,
        "L3A":	6,
        "L3B":	7,
        "L3C":	7,
        "L3E":	6,
        "L3G":	6,
        "L3H":	6,
        "L3J":	6,
        "L3K":	7,
        "L3L":	6,
        "L3M":	5,
        "L3N":	6,
        "L3P":	2,
        "L3R":	2,
        "L3S":	2,
        "L3T":	2,
        "L3V":	7,
        "L3W":	6,
        "L3X":	4,
        "L3Y":	4,
        "L3Z":	4,
        "L4A":	7,
        "L4B":	3,
        "L4C":	3,
        "L4E":	3,
        "L4G":	4,
        "L4H":	2,
        "L4J":	2,
        "L4K":	2,
        "L4L":	2,
        "L4M":	5,
        "L4N":	5,
        "L4P":	7,
        "L4R":	7,
        "L4S":	3,
        "L4T":	1,
        "L4V":	1,
        "L4W":	1,
        "L4X":	1,
        "L4Y":	1,
        "L4Z":	1,
        "L5A":	1,
        "L5B":	1,
        "L5C":	1,
        "L5E":	1,
        "L5G":	1,
        "L5H":	1,
        "L5J":	1,
        "L5K":	1,
        "L5L":	1,
        "L5M":	1,
        "L5N":	1,
        "L5P":	1,
        "L5R":	1,
        "L5S":	1,
        "L5T":	1,
        "L5V":	1,
        "L5W":	1,
        "L5X":	6,
        "L5Y":	6,
        "L5Z":	6,
        "L6A":	3,
        "L6B":	2,
        "L6C":	2,
        "L6E":	2,
        "L6G":	2,
        "L6H":	2,
        "L6J":	2,
        "L6K":	2,
        "L6L":	2,
        "L6M":	2,
        "L6N":	6,
        "L6P":	1,
        "L6R":	1,
        "L6S":	1,
        "L6T":	1,
        "L6V":	1,
        "L6W":	1,
        "L6X":	1,
        "L6Y":	1,
        "L6Z":	1,
        "L7A":	1,
        "L7B":	7,
        "L7C":	2,
        "L7E":	3,
        "L7G":	4,
        "L7H":	6,
        "L7J":	4,
        "L7K":	2,
        "L7L":	4,
        "L7M":	4,
        "L7N":	4,
        "L7P":	4,
        "L7R":	4,
        "L7S":	4,
        "L7T":	4,
        "L7V":	6,
        "L7W":	6,
        "L7X":	6,
        "L7Y":	6,
        "L7Z":	6,
        "L8A":	6,
        "L8B":	6,
        "L8C":	6,
        "L8E":	5,
        "L8G":	5,
        "L8H":	5,
        "L8J":	5,
        "L8K":	5,
        "L8L":	5,
        "L8M":	5,
        "L8N":	5,
        "L8P":	5,
        "L8R":	5,
        "L8S":	5,
        "L8T":	5,
        "L8V":	5,
        "L8W":	5,
        "L8X":	6,
        "L8Y":	6,
        "L8Z":	6,
        "L9A":	5,
        "L9B":	5,
        "L9C":	5,
        "L9E":	6,
        "L9G":	5,
        "L9H":	7,
        "L9J":	6,
        "L9K":	5,
        "L9L":	7,
        "L9M":	7,
        "L9N":	7,
        "L9P":	5,
        "L9R":	5,
        "L9S":	7,
        "L9T":	4,
        "L9V":	5,
        "L9W":	5,
        "L9X":	6,
        "L9Y":	7,
        "L9Z":	7,
        "M1A":	6,
        "M1B":	2,
        "M1C":	2,
        "M1E":	2,
        "M1G":	2,
        "M1H":	2,
        "M1J":	2,
        "M1K":	2,
        "M1L":	2,
        "M1M":	2,
        "M1N":	2,
        "M1P":	2,
        "M1R":	2,
        "M1S":	2,
        "M1T":	2,
        "M1V":	2,
        "M1W":	2,
        "M1X":	2,
        "M1Y":	6,
        "M1Z":	6,
        "M2A":	6,
        "M2B":	6,
        "M2C":	6,
        "M2E":	6,
        "M2G":	6,
        "M2H":	1,
        "M2J":	1,
        "M2K":	1,
        "M2L":	1,
        "M2M":	1,
        "M2N":	1,
        "M2P":	1,
        "M2R":	1,
        "M2S":	6,
        "M2T":	6,
        "M2V":	6,
        "M2W":	6,
        "M2X":	6,
        "M2Y":	6,
        "M2Z":	6,
        "M3A":	1,
        "M3B":	1,
        "M3C":	1,
        "M3E":	6,
        "M3G":	6,
        "M3H":	1,
        "M3J":	1,
        "M3K":	1,
        "M3L":	1,
        "M3M":	1,
        "M3N":	1,
        "M3P":	6,
        "M3R":	6,
        "M3S":	6,
        "M3T":	6,
        "M3V":	6,
        "M3W":	6,
        "M3X":	6,
        "M3Y":	6,
        "M3Z":	6,
        "M4A":	1,
        "M4B":	1,
        "M4C":	1,
        "M4E":	1,
        "M4G":	1,
        "M4H":	1,
        "M4J":	1,
        "M4K":	1,
        "M4L":	1,
        "M4M":	1,
        "M4N":	1,
        "M4P":	1,
        "M4R":	1,
        "M4S":	1,
        "M4T":	1,
        "M4V":	1,
        "M4W":	1,
        "M4X":	1,
        "M4Y":	1,
        "M4Z":	6,
        "M5A":	1,
        "M5B":	1,
        "M5C":	1,
        "M5E":	1,
        "M5G":	1,
        "M5H":	1,
        "M5J":	1,
        "M5K":	1,
        "M5L":	1,
        "M5M":	1,
        "M5N":	1,
        "M5P":	1,
        "M5R":	1,
        "M5S":	1,
        "M5T":	1,
        "M5V":	1,
        "M5W":	1,
        "M5X":	1,
        "M5Y":	6,
        "M5Z":	6,
        "M6A":	1,
        "M6B":	1,
        "M6C":	1,
        "M6E":	1,
        "M6G":	1,
        "M6H":	1,
        "M6J":	1,
        "M6K":	1,
        "M6L":	1,
        "M6M":	1,
        "M6N":	1,
        "M6P":	1,
        "M6R":	1,
        "M6S":	1,
        "M6T":	6,
        "M6V":	6,
        "M6W":	6,
        "M6X":	6,
        "M6Y":	6,
        "M6Z":	6,
        "M7A":	1,
        "M7B":	6,
        "M7C":	6,
        "M7E":	6,
        "M7G":	6,
        "M7H":	6,
        "M7J":	6,
        "M7K":	6,
        "M7L":	6,
        "M7M":	6,
        "M7N":	6,
        "M7P":	6,
        "M7R":	6,
        "M7S":	6,
        "M7T":	6,
        "M7V":	6,
        "M7W":	6,
        "M7X":	6,
        "M7Y":	1,
        "M7Z":	6,
        "M8A":	6,
        "M8B":	6,
        "M8C":	6,
        "M8E":	6,
        "M8G":	6,
        "M8H":	6,
        "M8J":	6,
        "M8K":	6,
        "M8L":	6,
        "M8M":	6,
        "M8N":	6,
        "M8P":	6,
        "M8R":	6,
        "M8S":	6,
        "M8T":	6,
        "M8V":	1,
        "M8W":	1,
        "M8X":	1,
        "M8Y":	1,
        "M8Z":	1,
        "M9A":	1,
        "M9B":	1,
        "M9C":	1,
        "M9E":	6,
        "M9G":	6,
        "M9H":	6,
        "M9J":	6,
        "M9K":	6,
        "M9L":	1,
        "M9M":	1,
        "M9N":	1,
        "M9P":	1,
        "M9R":	1,
        "M9S":	6,
        "M9T":	6,
        "M9V":	1,
        "M9W":	1,
        "M9X":	6,
        "M9Y":	6,
        "M9Z":	6,
        "P0A":	7,
        "P0B":	7,
        "P0C":	7,
        "P0E":	7,
        "P0G":	7,
        "P0H":	7,
        "P0J":	7,
        "P0K":	7,
        "P0L":	7,
        "P0M":	7,
        "P0N":	7,
        "P0P":	7,
        "P0R":	7,
        "P0S":	7,
        "P0T":	7,
        "P0V":	7,
        "P0W":	7,
        "P0X":	7,
        "P0Y":	7,
        "P0Z":	6,
        "P1A":	7,
        "P1B":	7,
        "P1C":	7,
        "P1E":	6,
        "P1G":	6,
        "P1H":	7,
        "P1J":	6,
        "P1K":	6,
        "P1L":	7,
        "P1M":	6,
        "P1N":	6,
        "P1P":	7,
        "P1R":	6,
        "P1S":	6,
        "P1T":	6,
        "P1V":	6,
        "P1W":	6,
        "P1X":	6,
        "P1Y":	6,
        "P1Z":	6,
        "P2A":	7,
        "P2B":	7,
        "P2C":	6,
        "P2E":	6,
        "P2G":	6,
        "P2H":	6,
        "P2J":	6,
        "P2K":	6,
        "P2L":	6,
        "P2M":	6,
        "P2N":	7,
        "P2P":	6,
        "P2R":	6,
        "P2S":	6,
        "P2T":	6,
        "P2V":	6,
        "P2W":	6,
        "P2X":	6,
        "P2Y":	6,
        "P2Z":	6,
        "P3A":	7,
        "P3B":	7,
        "P3C":	7,
        "P3E":	7,
        "P3G":	7,
        "P3H":	6,
        "P3J":	6,
        "P3K":	6,
        "P3L":	7,
        "P3M":	6,
        "P3N":	7,
        "P3P":	7,
        "P3R":	6,
        "P3S":	6,
        "P3T":	6,
        "P3V":	6,
        "P3W":	6,
        "P3X":	6,
        "P3Y":	7,
        "P3Z":	6,
        "P4A":	6,
        "P4B":	6,
        "P4C":	6,
        "P4E":	6,
        "P4G":	6,
        "P4H":	6,
        "P4J":	6,
        "P4K":	6,
        "P4L":	6,
        "P4M":	6,
        "P4N":	7,
        "P4P":	7,
        "P4R":	7,
        "P4S":	6,
        "P4T":	6,
        "P4V":	6,
        "P4W":	6,
        "P4X":	6,
        "P4Y":	6,
        "P4Z":	6,
        "P5A":	7,
        "P5B":	6,
        "P5C":	6,
        "P5E":	7,
        "P5G":	6,
        "P5H":	6,
        "P5J":	6,
        "P5K":	6,
        "P5L":	6,
        "P5M":	6,
        "P5N":	7,
        "P5P":	6,
        "P5R":	6,
        "P5S":	6,
        "P5T":	6,
        "P5V":	6,
        "P5W":	6,
        "P5X":	6,
        "P5Y":	6,
        "P5Z":	6,
        "P6A":	7,
        "P6B":	7,
        "P6C":	7,
        "P6E":	6,
        "P6G":	6,
        "P6H":	6,
        "P6J":	6,
        "P6K":	6,
        "P6L":	6,
        "P6M":	6,
        "P6N":	6,
        "P6P":	6,
        "P6R":	6,
        "P6T":	6,
        "P6S":	6,
        "P6V":	6,
        "P6W":	6,
        "P6X":	6,
        "P6Y":	6,
        "P6Z":	6,
        "P7A":	7,
        "P7B":	7,
        "P7C":	7,
        "P7E":	7,
        "P7G":	7,
        "P7H":	6,
        "P7J":	7,
        "P7K":	7,
        "P7L":	7,
        "P7M":	6,
        "P7N":	6,
        "P7P":	6,
        "P7R":	6,
        "P7S":	6,
        "P7T":	6,
        "P7V":	6,
        "P7W":	6,
        "P7X":	6,
        "P7Y":	6,
        "P7Z":	6,
        "P8A":	6,
        "P8B":	6,
        "P8C":	6,
        "P8E":	6,
        "P8G":	6,
        "P8H":	6,
        "P8J":	6,
        "P8K":	6,
        "P8L":	6,
        "P8M":	6,
        "P8N":	7,
        "P8P":	6,
        "P8R":	6,
        "P8S":	6,
        "P8T":	7,
        "P8V":	6,
        "P8W":	6,
        "P8X":	6,
        "P8Y":	6,
        "P8Z":	6,
        "P9A":	7,
        "P9B":	6,
        "P9C":	6,
        "P9E":	6,
        "P9G":	6,
        "P9H":	6,
        "P9J":	6,
        "P9K":	6,
        "P9L":	6,
        "P9M":	6,
        "P9N":	7,
        "P9P":	6,
        "P9R":	6,
        "P9S":	6,
        "P9T":	6,
        "P9V":	6,
        "P9W":	6,
        "P9X":	6,
        "P9Y":	6,
        "P9Z":	6
      },
      "zones": {
        1: {
          "rates": [
            { "min": 1, "max": 25, "price": 23.5 },
            { "min": 26, "max": 90, "price": 30 },
            { "min": 91, "max": 225, "price": 44 },
            { "min": 226, "max": 340, "price": 48 },
            { "min": 341, "max": 455, "price": 65 },
            { "min": 456, "max": 910, "price": 72 },
            { "min": 911, "max": 1360, "price": 92 },
            { "min": 1361, "max": 1815, "price": 110 },
            { "min": 1816, "max": 2270, "price": 130 },
            { "min": 2271, "max": 2720, "price": 140 },
            { "min": 2721, "max": 3175, "price": 152 },
            { "min": 3176, "max": 4535, "price": 175 }
          ]
        },
        2: {
          "rates": [
            { "min": 1, "max": 25, "price": 26 },
            { "min": 26, "max": 90, "price": 36 },
            { "min": 91, "max": 225, "price": 48 },
            { "min": 226, "max": 340, "price": 58 },
            { "min": 341, "max": 455, "price": 64 },
            { "min": 456, "max": 910, "price": 75 },
            { "min": 911, "max": 1360, "price": 95 },
            { "min": 1361, "max": 1815, "price": 110 },
            { "min": 1816, "max": 2270, "price": 135 },
            { "min": 2271, "max": 2720, "price": 150 },
            { "min": 2721, "max": 3175, "price": 160 },
            { "min": 3176, "max": 4535, "price": 185 }
          ]
        },
        3: {
          "rates": [
            { "min": 1, "max": 25, "price": 52 },
            { "min": 26, "max": 90, "price": 60 },
            { "min": 91, "max": 225, "price": 72 },
            { "min": 226, "max": 340, "price": 82 },
            { "min": 341, "max": 455, "price": 100 },
            { "min": 456, "max": 910, "price": 110 },
            { "min": 911, "max": 1360, "price": 160 },
            { "min": 1361, "max": 1815, "price": 190 },
            { "min": 1816, "max": 2270, "price": 225 },
            { "min": 2271, "max": 2720, "price": 230 },
            { "min": 2721, "max": 3175, "price": 245 },
            { "min": 3176, "max": 4535, "price": 275 }
          ]
        },
        4: {
          "rates": [
            { "min": 1, "max": 25, "price": 53 },
            { "min": 26, "max": 90, "price": 60 },
            { "min": 91, "max": 225, "price": 72 },
            { "min": 226, "max": 340, "price": 83 },
            { "min": 341, "max": 455, "price": 100 },
            { "min": 456, "max": 910, "price": 110 },
            { "min": 911, "max": 1360, "price": 190 },
            { "min": 1361, "max": 1815, "price": 210 },
            { "min": 1816, "max": 2270, "price": 230 },
            { "min": 2271, "max": 2720, "price": 250 },
            { "min": 2721, "max": 3175, "price": 280 },
            { "min": 3176, "max": 4535, "price": 300 }
          ]
        },
        5: {
          "rates": [
            { "min": 1, "max": 115, "price": 75 },
            { "min": 116, "max": 225, "price": 82 },
            { "min": 226, "max": 453, "price": 89 },
            { "min": 454, "max": 654, "price": 105.5 },
            { "min": 655, "max": 1500, "price": 118, "multiply": 0.032 },
            { "min": 1501, "max": 5000, "price": 150, "multiply": 0.025 },
            { "min": 5001, "max": 8000, "price": 165, "multiply": 0.025 },
          ]
        },
      }
    },
  };