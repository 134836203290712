import { createReducer } from 'redux-act';

import {
  FTL_FETCH_DATA_INIT,
  FTL_FETCH_DATA_SUCCESS,
  FTL_FETCH_DATA_FAIL,
  FTL_DELETE_FTL_INIT,
  FTL_DELETE_FTL_SUCCESS,
  FTL_DELETE_FTL_FAIL,
	FTL_CREATE_FTL_INIT,
	FTL_CREATE_FTL_SUCCESS,
	FTL_CREATE_FTL_FAIL,
	FTL_MODIFY_FTL_INIT,
	FTL_MODIFY_FTL_SUCCESS,
	FTL_MODIFY_FTL_FAIL,
  FTL_CLEAN_UP,
} from 'state/actions/ftl';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
	deleted: false,
};

export const ftlReducer = createReducer(
  {
    [FTL_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [FTL_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [FTL_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FTL_DELETE_FTL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FTL_DELETE_FTL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id ),
      loading: false,
      error: null,
			deleted: true,
    }),
    [FTL_DELETE_FTL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FTL_CREATE_FTL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FTL_CREATE_FTL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.rate),
      loading: false,
      error: null,
      success: true,
    }),
    [FTL_CREATE_FTL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FTL_MODIFY_FTL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [FTL_MODIFY_FTL_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
								rate: payload.rate,
								markup: payload.markup,
								cost: payload.cost,
								city: payload.city,
								state: payload.state,
                id: payload.id,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [FTL_MODIFY_FTL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [FTL_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
			deleted: false,
    }),
  },
  initialState
);
