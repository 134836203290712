import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import GuestNav from '../../../components/Navigation/GuestNav';
import { formatCurrency } from '../../../utils'; 

const FTLForm = ({ rates }) => {
	const [optionsState, setOptionsState ] = useState([]);
	const [optionsCities, setOptionsCities ] = useState([]);
	const [cities, setCities ] = useState([]);
	const [state, setState] = useState();
	const [city, setCity] = useState();
	const [rate, setRate] = useState();

	useEffect(() => {
		const _optionsState = [];
		const _cities = [];

		rates.forEach(_rate => {
			_optionsState.push({
				value: _rate.state,
				label: _rate.state
			});
			_cities.push({
				value: _rate.city,
				label: _rate.city,
				state: _rate.state,
				rate: _rate.rate,
			});
		});

		setOptionsState(_optionsState);
		setCities(_cities);
	}, [rates]);

	useEffect(() => {
		// Reset Rate and City
		setCity();
		setRate();

		setOptionsCities(cities.filter(e => e.state === state));
	}, [state]);

	useEffect(() => {
		const foundCity = rates.find(e => e.city === city);
		if (foundCity) {
			setRate(foundCity);
		}
	}, [city]);

	return (
		<section className="section">
			<div className="container">
				<div className="box">
					<p>All loads must be of legal weight (max 44000 lbs).</p>
					<p>Freight must be palletized, crated or skidded. No loose freight will be accepted the the below rates.</p>
					<p>In Spring, during thaw season, the maximum weight may be reduced up to 10000 lbs (max 34000 lbs).</p>
					<p>Rates are based on availability at time of booking.</p>
				</div>
				<form autoComplete="off">
					<div className="block">
						<div className="field">
							<p className="label">Point of Origin</p>
							<div className="control is-clearfix">
								<input type="text" name="origin" value="Greater Montreal Area" readOnly />
							</div>
						</div>

						<div className="field">
							<p className="label">Destination State</p>
							<div className="control is-clearfix">
								<select name="destinationState" onChange={e => setState(e.target.value)}>
									<option value="" key="state-empty">Choose</option>
									{optionsState.map(({ value, label }) => <option key={value} value={value}>{label}</option> )}
								</select>
							</div>
						</div>

						<div className="field">
							<p className="label">Destination City</p>
							<div className="control is-clearfix">
								<select name="destinationCity" onChange={e => setCity(e.target.value)}>
									<option value="" key="city-empty">Choose</option>
									{optionsCities.map(({ value, label }) => <option key={value} value={value}>{label}</option> )}
								</select>
							</div>
						</div>

						{city && state && rate && 
							<p>Rate: {formatCurrency('en-US', 'USD', rate.rate)}</p>
						}

					</div>
				</form>
			</div>
		</section>
	);
};

const QuotesFTL = () => {
	const [ rates, setRates ] = useState([]);
	const [ loading, setLoading ] = useState();
	const [ error, setError ] = useState();

	const getRates = async() => {
		try {
			const response = await fetch("https://etabackend-hmhksgqjfa-nn.a.run.app/v1/ftl/rates");
			if (!response.ok) {
				setError("Something bad happened. Please retry in a few moments and if the error persists, contact us.");
				setLoading(false);
				return;
			}
			const responseJSON = await response.json();
			setRates(responseJSON.rates);
			setLoading(false);
			// Add the rates in the state
		} catch (e) {
				setError("Something bad happened. Please retry in a few moments and if the error persists, contact us.");
				setLoading(false);
		}
	};

	useEffect(() => {
		getRates();
	}, []);

	useEffect(() => {
		document.documentElement.classList.remove(
			'has-aside-left',
			'has-navbar-fixed-top'
		);
	});

	const Content = () => {
		return error ? <p>{error}</p> : <FTLForm rates={rates} />;
	};

	return (
		<section className="section hero">
			<div className="hero-head">
				<GuestNav />
			</div>
			<div className="hero-body">
				<h1 className="title has-text-centered">FTL</h1>
				{loading ? <ClipLoader /> : <Content error={error} rates={rates} />
				}
			</div>
		</section>
	);
};

export default QuotesFTL;
