export default {
  ROOT: '/',
  GUEST: '/guest',
  QUOTESDAP: '/guest/quotes/dap',
  QUOTESAIREXPORT: '/guest/quotes/airexport',
  QUOTESLTL: '/guest/quotes/ltl',
  QUOTESFTL: '/guest/quotes/ftl',
  LOGIN: '/login',
  SIGNUP: '/signup',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  FTLS: '/FTLs',
  ADD_FTL: '/FTL/new',
  MODIFY_FTL: '/FTL/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2'
};
