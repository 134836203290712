import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import { checkUserData } from './auth';
import {
	fetchDocument,
	deleteDocument,
	updateDocument,
  fetchCollection,
	addDocument,
} from '../api';

export const FTL_FETCH_DATA_INIT = createAction('FTL_FETCH_DATA_INIT');
export const FTL_FETCH_DATA_SUCCESS = createAction(
  'FTL_FETCH_DATA_SUCCESS'
);
export const FTL_FETCH_DATA_FAIL = createAction('FTL_FETCH_DATA_FAIL');

export const FTL_DELETE_FTL_INIT = createAction('FTL_DELETE_FTL_INIT');
export const FTL_DELETE_FTL_SUCCESS = createAction('FTL_DELETE_FTL_SUCCESS');
export const FTL_DELETE_FTL_FAIL = createAction('FTL_DELETE_FTL_FAIL');

export const FTL_CREATE_FTL_INIT = createAction('FTL_CREATE_FTL_INIT');
export const FTL_CREATE_FTL_SUCCESS = createAction('FTL_CREATE_FTL_SUCCESS');
export const FTL_CREATE_FTL_FAIL = createAction('FTL_CREATE_FTL_FAIL');

export const FTL_MODIFY_FTL_INIT = createAction('FTL_MODIFY_FTL_INIT');
export const FTL_MODIFY_FTL_SUCCESS = createAction('FTL_MODIFY_FTL_SUCCESS');
export const FTL_MODIFY_FTL_FAIL = createAction('FTL_MODIFY_FTL_FAIL');

export const FTL_CLEAN_UP = createAction('FTL_CLEAN_UP');

export const deleteRate = (id) => {
  return async (dispatch, getState) => {
    dispatch(FTL_DELETE_FTL_INIT());
    const { locale } = getState().preferences;

    try {
      await deleteDocument('FTL', id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        FTL_DELETE_FTL_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The rate was deleted.');
    return dispatch(FTL_DELETE_FTL_SUCCESS({ id }));
  };
};

export const createRate = ({
	city,
	state,
	cost,
	markup,
	rate,
}) => { 
	return async (dispatch) => {
		dispatch(FTL_CREATE_FTL_INIT());
		
		if (markup > 100 || markup < 0) {
			const errorMessage = 'Markup has to be between 0 and 1';
			toastr.error('', errorMessage);
			return dispatch(
				FTL_CREATE_FTL_FAIL({
					error: errorMessage,
				})
			);
		}

		const data = { city, state, cost, markup, rate };
		
		try {
			const response = await addDocument('FTL', data);
			toastr.success('', 'Rate created successfully');
			return dispatch(FTL_CREATE_FTL_SUCCESS({ rate: response }));
		} catch (error) {
			toastr.error('', error);
			return dispatch(
				FTL_CREATE_FTL_FAIL({
					error,
				})
			);
		}

	};
};

export const modifyRate = ({
	city,
	state,
	cost,
	markup,
	rate,
	id
}) => {
	return async (dispatch, getState) => {
		dispatch(FTL_MODIFY_FTL_INIT());
		const { locale } = getState().preferences;

		if (markup > 100 || markup < 0) {
			const errorMessage = 'Markup has to be between 0 and 1';
			toastr.error('', errorMessage);
			return dispatch(
				FTL_MODIFY_FTL_FAIL({
					error: errorMessage,
				})
			);
		}


		const data = { city, state, cost, markup, rate };

		try {
			const response = await updateDocument('FTL', id, data);
			toastr.success('', 'Rate updated successfully');
			return dispatch(FTL_MODIFY_FTL_SUCCESS({ rate: response }));
		} catch (error) {
			const errorMessage = firebaseError(error.code, locale);
			toastr.error('', errorMessage);
			return dispatch(
				FTL_MODIFY_FTL_FAIL({
					error: errorMessage,
				})
			);
		}

	};
};

export const fetchRates = (rateId = '') => {
  return async (dispatch, getState) => {
		dispatch(checkUserData());
    dispatch(FTL_FETCH_DATA_INIT());

		if (rateId) {
			let rate;
			try {
				rate = await fetchDocument('FTL', rateId);
			} catch (error) {
				toastr.error('', error);
				return dispatch(FTL_FETCH_DATA_FAIL({ error }));
			}

			if (!rate) {
				const errorMessage = 'Rate not available';
				toastr.error('', errorMessage);
				return dispatch(FTL_FETCH_DATA_FAIL({ error: errorMessage }));
			}

			const rates = getState().rates.data;
			rates.push(rate);

			return dispatch(
				FTL_FETCH_DATA_SUCCESS({
					data: rates,
				})
			);
		}

		let ftl;

    try {
      ftl = await fetchCollection('FTL');
    } catch (error) {
      return dispatch(FTL_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      FTL_FETCH_DATA_SUCCESS({
        data: ftl,
      })
    );
  };
};

export const ftlCleanUp = () => (dispatch) => dispatch(FTL_CLEAN_UP());
