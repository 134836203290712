import React from 'react';
import { toastr } from 'react-redux-toastr';
import QuotesLTLAddress from './address';
import QuotesLTLParcels from './parcels';
import QuotesLTLResult from './result';
import GuestNav from '../../../components/Navigation/GuestNav';

export default class QuotesLTL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      sender: {
        number: undefined,
        postalCode: undefined,
        countryCode: undefined
      },
      consignee: {
        number: undefined,
        postalCode: undefined,
        countryCode: undefined
      },
      paymentType: undefined,
      deliveryType: undefined,
      category: undefined,
      unitOfMeasurement: 'K',
      parcels: []
    };
  }

  componentDidMount = () => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
  };

  finish = () => {
    toastr.error('', 'Coming Soon in next update.');
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

	handleChangeObject = (root, property) => e => {
		// eslint-disable-next-line
		const current = this.state[root];
		this.setState({
			[root]: {
				...current,
				[property]: e.target.value
			}
		});
	}

  updateParcels = (parcels) => {
    this.setState({ parcels });
  }

  /* eslint-disable */
  render() {
    const { step, sender, consignee, paymentType, deliveryType, category, unitOfMeasurement, parcels } = this.state;
    const values = { sender, consignee, paymentType, deliveryType, category, unitOfMeasurement, parcels }

    switch (step) {
      case 1:
        return (
          <section className="section hero">
            <div className="hero-head">
              <GuestNav />
            </div>
            <div className="hero-body">
              <div className="container">
                <QuotesLTLAddress
                  nextStep={ this.nextStep }
									handleChange={ this.handleChange }
									handleChangeObject={ this.handleChangeObject }
                  values={ values }
                />
              </div>
            </div>
          </section>
        );
      case 2:
        return (
          <section className="section hero">
            <div className="hero-head">
              <GuestNav />
            </div>
            <div className="hero-body">
              <div className="container">
                <QuotesLTLParcels
									prevStep={ this.prevStep}
                  nextStep={ this.nextStep }
                  handleChange={ this.handleChange }
                  updateParcels={ this.updateParcels }
                  values={ values }
                />
              </div>
            </div>
          </section>
        );
        case 3:
          return (
            <section className="section hero">
              <div className="hero-head">
                <GuestNav />
              </div>
              <div className="hero-body">
                <div className="container">
                  <QuotesLTLResult
                    prevStep={ this.prevStep }
                    nextStep={ this.finish }
                    values={ values }
                  />
                </div>
              </div>
            </section>
          );
      default:
    }
  }
  /* eslint-enable */
};
