import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';

const schema = yup.object().shape({
  from: yup.string().min(3).required(),
  postalCode: yup.string().min(3).max(3).required()
});

const QuotesDAPBasicInformation = ({ nextStep, handleChange, values }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(values);
  }, []);

  const onSubmitHandler = () => {
    nextStep();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
        
        <div className='block'>
          <h2 className='title'>From</h2>
          <div className="field">
            <div className="control is-clearfix">
              <div
                className={classNames('select', {
                  'is-danger': errors.from,
                })}
              >
                <select name="from" {...register('from')} onChange={handleChange('from')}>
                  <option value="">Select a value</option>
                  <option value="YYC">YYC (Calgary)</option>
                  <option value="YEG">YEG (Edmonton)</option>
                  <option value="YHZ">YHZ (Halifax)</option>
                  <option value="YVR">YVR (Vancouver)</option>
                  <option value="YYZ">YYZ (Toronto)</option>
                  <option value="YUL">YUL (Montreal)</option>
                </select>
              </div>
            </div>
            {errors.companyType && (
              <ErrorMessage text='Invalid area type.' />
            )}
          </div>
        </div>

        <div className='block'>
          <h2 className='title'>To</h2>
          <div className='field'>
            <p className="label">Postal Code (Enter the first three characters).</p>
            <div className="control is-clearfix">
              <input
                {...register('postalCode')}
                className={classNames('input', {
                  'is-danger': errors.postalCode,
                })}
                style={{ width: "initial" }}
                name="postalCode"
                maxLength="3"
                onChange={handleChange('postalCode')}
                onInput={handleChange('postalCode')}
              />
            </div>
            {errors.name && (
              <ErrorMessage text='Invalid postal code.' />
            )}
          </div>

        </div>

        <div className='field'>
          <button type='submit' className='button is-black'>Next</button>
        </div>
      </form>
    </div>
  );
};

export default QuotesDAPBasicInformation;
