import React from 'react';

class QuotesLTLResult extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			input: {
				...props.values
			},
			ready: false,
			error: undefined,
			total: 0
		};
	}

  async componentDidMount () {
		const { input } = this.state;
		
		// Cleaning the data
		/* eslint-disable no-param-reassign */
		for (let i = 0; i < input.parcels.length; i+=1) {
			input.parcels[i].quantity = parseInt(input.parcels[i].quantity, 10);
			input.parcels[i].length = parseFloat(input.parcels[i].length);
			input.parcels[i].width = parseFloat(input.parcels[i].width);
			input.parcels[i].depth = parseFloat(input.parcels[i].depth);
			input.parcels[i].weight = parseFloat(input.parcels[i].weight);
		}
		input.sender.number = parseInt(input.sender.number, 10);
		input.consignee.number = parseInt(input.consignee.number, 10);
		input.paymentType = "ThirdParty";
		input.category = "Parcel";
		/* eslint-enable no-param-reassign */
		try {
			const response = await fetch("https://etabackend-hmhksgqjfa-nn.a.run.app/v1/gls/rate", {
				method: "POST",
				cache: "no-cache",
				mode: "cors",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(input)
			});

			if (!response.ok) {
				this.setState({ error: "Something went wrong. Please try again in a few minutes or contact us." });
			}
			const responseJSON = await response.json();
			this.processResponse(responseJSON);
		} catch (e) {
			this.setState({ error: "Something went wrong. Please try again in a few minutes or contact us." });
		}
  }

	processResponse(data) {
		if ("error" in data) {
			this.setState({ error: "Something went wrong. Please try again in a few minutes or contact us." });
			return;
		}

		if ("rates" in data.rates) {
			if (data.rates.rates && data.rates.rates.length > 0) {
				const { rates } = data.rates;
				const negociated = rates.find(x => x.accountType === "NEG");
				if (negociated) {
					this.setState({ ready: true, total: negociated.total });
				} else {
					this.setState({ ready: true, total: data.rates.rates[0].total });
				}
			} else {
				this.setState({ error: "No rates are available. Please double-check what you entered or contact us."});
			}
		} else {
				this.setState({ error: "No rates are available. Please double-check what you entered or contact us."});
		}
	}

  render() {
    const { prevStep, nextStep } = this.props;
    const { error, ready, total } = this.state;
    return (
      <section className="section hero">
				{!ready && !error &&
					<p>Generating the quote, please wait...</p>
				}
        {error &&
          <div className="box">{ error }</div>
        }
        {ready &&
          <div>
            <div className="box">
							<p>Your quote</p>
							<p>Total: { total } CAD</p>
            </div>
          </div>
        }
        <div className="field is-grouped">
          <p className="control">
            <button
              type="button"
              className='button is-black'
              onClick={() => prevStep() }
            >Previous</button>
          </p>
          <p className="control">
            <button
              type="button"
              className='button is-black'
              onClick={() => nextStep() }
            >Book it</button>
          </p>
        </div>
        
      </section>
    );
  }
};

export default QuotesLTLResult;
