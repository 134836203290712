import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  authCleanUp,
  signUp,
} from 'state/actions/auth';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import paths from 'pages/Router/paths';
import classes from './SignUp.module.scss';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
  company: yup.string().min(3).required(),
  street: yup.string().min(3).required(),
  city: yup.string().min(3).required(),
  postcode: yup.string().min(3).required(),
  country: yup.string().min(3).required(),
  firstName: yup.string().min(3).required(),
  lastName: yup.string().min(3).required(),
  companyType: yup.string().min(3).required(),
});

const SignUp = () => {
  const { error, isAuth, loading } = useSelector(
    (state) => ({
      error: state.auth.error,
      isAuth: !!state.auth.userData.id,
      loading: state.auth.loading,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  const onSubmitHandler = ({ email, password, company, street, city, postcode, country, lastName, firstName, companyType }) => {
    dispatch(signUp(email, password, company, street, city, postcode, country, lastName, firstName, companyType));
  };

  const isValidPassword = watch('password') && watch('password').length >= 6;

  const invalidEmailMessage = useFormatMessage('SignUp.invalidEmail');
  
  const invalidTextMessage = useFormatMessage('SignUp.invalidText');

  const invalidSelectMessage = useFormatMessage('SignUp.invalidSelect');

  const safePasswordMessage = useFormatMessage('SignUp.safePassword');

  const redirect = isAuth && <Redirect to={paths.ROOT} />;

  const signUpMessage = useFormatMessage('SignUp.signUp');

  const asGuestMessage = useFormatMessage('Login.asGuest');

  const loginMessage = useFormatMessage('Login.login');

  const invalidPasswordMessage = useFormatMessage('SignUp.invalidPassword');

  return (
    <section className="section hero is-fullheight is-error-section">
      {redirect}
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-two-fifths">
              <div className="card has-card-header-background">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-lock default" />
                    </span>
                    <span>
                      {signUpMessage}
                    </span>
                  </p>
                </header>
                <div className="card-content">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.email')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.email,
                          })}
                          name="email"
                          {...register('email')}
                        />
                      </div>
                      {errors.email && (
                        <ErrorMessage text={invalidEmailMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">
                        {useFormatMessage('SignUp.password')}
                      </p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames(
                            'input',
                            {
                              'is-danger': errors.password,
                            },
                            {
                              'is-success': isValidPassword,
                            }
                          )}
                          type="password"
                          name="password"
                          {...register('password')}
                        />
                      </div>
                      {errors.password ? (
                        <ErrorMessage
                          text={invalidPasswordMessage}
                        />
                      ) : (
                        isValidPassword && (
                          <p className="is-success">{safePasswordMessage}</p>
                        )
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.company')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.company,
                          })}
                          name="company"
                          {...register('company')}
                        />
                      </div>
                      {errors.company && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.street')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.street,
                          })}
                          name="street"
                          {...register('street')}
                        />
                      </div>
                      {errors.street && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.city')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.city,
                          })}
                          name="city"
                          {...register('city')}
                        />
                      </div>
                      {errors.city && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.postcode')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.postcode,
                          })}
                          name="postcode"
                          {...register('postcode')}
                        />
                      </div>
                      {errors.postcode && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.country')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.country,
                          })}
                          name="country"
                          {...register('country')}
                        />
                      </div>
                      {errors.country && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.firstName')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.firstName,
                          })}
                          name="firstName"
                          {...register('firstName')}
                        />
                      </div>
                      {errors.firstName && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.lastName')}</p>
                      <div className="control is-clearfix">
                        <input
                          className={classNames('input', {
                            'is-danger': errors.lastName,
                          })}
                          name="lastName"
                          {...register('lastName')}
                        />
                      </div>
                      {errors.lastName && (
                        <ErrorMessage text={invalidTextMessage} />
                      )}
                    </div>
                    <div className="field">
                      <p className="label">{useFormatMessage('SignUp.companyType')}</p>
                      <div className="control is-clearfix">
                        <div
                          className={classNames('select', {
                            'is-danger': errors.companyType,
                          })}
                        >
                          <select name="companyType" {...register('companyType')}>
                            <option value="">Select a value</option>
                            <option value="Shipper">Shipper</option>
                            <option value="3rd Party Provider">3rd Party Provider</option>
                            <option value="International Forwarder">International Forwarder</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      {errors.companyType && (
                        <ErrorMessage text={invalidSelectMessage} />
                      )}
                    </div>
                    <br />
                    <div className="field is-grouped">
                      <div className="control">
                        <button
                          type="submit"
                          className={classNames('button', 'is-black', {
                            'is-loading': loading,
                          })}
                        >
                          {signUpMessage}
                        </button>
                      </div>
                    </div>
                    {error && (
                      <p
                        className={classNames(
                          'has-text-danger',
                          classes.errorMessage
                        )}
                      >
                        {error}
                      </p>
                    )}
                    <hr />
                    <div className="field is-grouped is-grouped-centered">
                      <div className="control">
                        <Link
                          to={paths.GUEST}
                          className="button is-outlined"
                        >
                          {asGuestMessage}
                        </Link>
                      </div>
                      <div className="control">
                        <Link
                          to={paths.LOGIN}
                          className="button is-outlined"
                        >
                          {loginMessage}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
