import React from 'react';
// import { toastr } from 'react-redux-toastr';
// import classNames from 'classnames';
// import classes from './Quotes.module.scss';

const QuotesAirExport = () => {
  return (
    <section className="section hero">
      <p>Quotes Air Export</p>
    </section>
  );
};

export default QuotesAirExport;
