import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import FTLRateForm from 'components/FTLRateForm';
import { createRate, modifyRate, fetchRates } from 'state/actions/ftl';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const schema = yup.object().shape({
	city: yup.string().required("Please choose a valid US State"),
	state: yup.string().required(),
	cost: yup.number().positive().required(),
	rate: yup.number().required(),
	markup: yup.number().min(0).max(100).required(),
});

const FTL = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, rateData, error } = useSelector(
    (state) => ({
      success: state.ftl.success,
      rateData: state.ftl.data.find((rate) => rate.id === id),
      error: state.ftl.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!rateData) {
        dispatch(fetchRates(id));
      }
    }
  }, [isEditing, id, rateData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.FTLS} />
  );

  const editRateMessage = useFormatMessage('Rate.editRate');
  const newRateMessage = useFormatMessage('Rate.newRate');

  const onSubmitHandler = (value) => {
    const newRate = {
      ...value,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyRate(newRate));
    } else {
      dispatch(createRate(newRate));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editRateMessage : newRateMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !rateData ? (
          <ClipLoader />
        ) : (
          <FTLRateForm
            isEditing={isEditing}
            rate={
              isEditing
                ? rateData
                : {
                    city: '',
                    state: '',
										markup: 0,
										cost: 0,
										rate: 0,
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default FTL;
