/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import paths from 'pages/Router/paths';
import { ftlCleanUp } from 'state/actions/ftl';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import { US_STATES } from '../../utils';

import './FTLRateForm.scss';

const FTLRateForm = ({ rate, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.ftl.loading,
      success: state.ftl.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    defaultValues: { ...rate },
    resolver: yupResolver(schema),
  });

	const markup = watch('markup');
	const cost = watch('cost');

  useEffect(() => {
    return () => dispatch(ftlCleanUp());
  }, [dispatch, success, setValue]);

	useEffect(() => {
		const coeff = 1 + markup / 100;
		setValue('rate', Math.trunc(cost * coeff));
	}, [markup, cost]);

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('FTLRateForm.rateInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
											State
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
												<div className="select is-fullwidth">
													<select
														name="state"
														id="state"
														{...register('state')}
													>
														<option value="">Choose a US State</option>
														{US_STATES.map(i =>
															<option key={i} value={i}>{i}</option>
														)}
													</select>
												</div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.state && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
											City
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          {...register('city')}
                          name="city"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.city && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
										  Cost
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field has-addons">
											<div className="control is-clearfix">
												<button className="button is-static">$</button>
											</div>
                      <div className="control is-expanded">
                        <input
                          className="input"
                          type="number"
                          {...register('cost')}
                          name="cost"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.cost && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
										  Markup
                    </label>
                  </div>
                  <div className="field-body">
										<div className="field has-addons has-addons-left">
											<div className="control is-clearfix">
												<button className="button is-static">%</button>
											</div>
                      <div className="control is-expanded">
                        <input
                          className="input"
													max="100"
													min="0"
													step="1"
                          type="number"
                          {...register('markup')}
                          name="markup"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.markup && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal hidden">
                  <div className="field-label is-normal">
                    <label className="label">
										  Displayed Rate
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          {...register('rate')}
                          name="rate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>Submit</span>
                          </button>
                        </div>
												<Link to={paths.FTLS} className="button">
												 Go Back	
												</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
								Rate Preview
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
									State
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="state"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('state')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
									City
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="city"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('city')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
								  Cost	
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="cost"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('cost')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
								 Markup 
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="markup"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('markup')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
								  Displayed Rate 
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="rate"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
										value={watch('rate')}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FTLRateForm.propTypes = {
  rate: PropTypes.shape({
    id: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		cost: PropTypes.number,
		markup: PropTypes.number,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

FTLRateForm.defaultProps = {
  isEditing: false,
};

export default FTLRateForm;
