import React from 'react';
import { Link } from 'react-router-dom';
import paths from 'pages/Router/paths';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import classes from './GuestNav.module.scss';

const GuestNav = () => {

  const preventSignup = (e) => {
    e.preventDefault();
    toastr.error('', 'Signup is deactivated. Please contact the admin.');
  };

  return (
    <div className='container'>
      <div
        className={classNames(
          'buttons',
          'are-medium',
          classes.buttons
        )}
      >
        <Link to={paths.GUEST} className='button'>Guest</Link>
        <Link to={paths.LOGIN} className='button'>Login</Link>
        <Link to={paths.SIGNUP} className='button' onClick={(e) => preventSignup(e)} >Signup</Link>
      </div>
    </div>
  );
};

export default GuestNav;
