import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const unitsFormat = {
  'L': {
    'weight': 'LB',
    'length': 'INCH'
  },
  'K': {
    'weight': 'KG',
    'length': 'M'
  }
};

const QuotesLTLParcels = ({ prevStep, nextStep, updateParcels, handleChange, values }) => {
  const schema = yup.object().shape({
    parcels: yup.array().of(
      yup.object().shape({
        parcelType: yup.string().required(),
        quantity: yup.number().required().positive().integer().max(99),
        length: yup.number().required().positive(),
        width: yup.number().required().positive(),
        depth: yup.number().required().positive(),
        weight: yup.number().required().positive(),
      })
    ).required(),
		unitOfMeasurement: yup.mixed().oneOf(['L', 'K']).defined()
  });

  const formOptions = { resolver: yupResolver(schema) };

  const { register, control, handleSubmit, watch } = useForm(formOptions);
  const { fields, append, remove, replace } = useFieldArray({ name: 'parcels', control });
  const watchParcels = watch('parcels');

  const onSubmitHandler = () => {
    nextStep();
  };

  useEffect(() => {
    replace(values.parcels);
  }, []);

  useEffect(() => {
    updateParcels(watchParcels);
  }, [watchParcels]);

  const pushParcel = () => {
    const pack = {
			parcelType: '', quantity: 0, length: 0, width: 0, weight: 0, depth: 0
    };
    append(pack);
  };

  const removeLastParcel = (index) => {
    remove(index);
  };

  /* eslint-disable */
  return (
    <section className="section hero">
      <div className="box">
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <p>Measurements Units</p>
          <div className="field">
            <div className="control">
              <label htmlFor="unitsMetric" className="radio">
                <input
                  {...register("unitOfMeasurement")}
                  onChange={handleChange('unitOfMeasurement')}
                  type="radio"
                  value="K"
                  name="unitOfMeasurement"
                  id="unitsMetric"
                  checked={ values.unitOfMeasurement === "K" }
                />
                <span style={{marginLeft:8}}>Metric (M and KG)</span>
              </label>
              <label htmlFor="unitsImperial" className="radio">
                <input
                  {...register("unitOfMeasurement")}
                  onChange={handleChange('unitOfMeasurement')}
                  type="radio"
                  value="L"
                  name="unitOfMeasurement"
                  id="unitsImperial"
                  checked={ values.unitOfMeasurement === "L" }
                />
                <span style={{ marginLeft:8 }}>Imperial (INCH and LBS)</span>
              </label>
            </div>
          </div>

          <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => pushParcel() }
              >Add a parcel</button>
            </p>
            {fields.length > 0 &&
              <p className="control">
                <button
                  type="button"
                  className='button is-black'
                  onClick={() => removeLastParcel(fields.length - 1) }
                >Remove last parcel</button>
              </p>
            }
          </div>

          {fields.map((item, i) => (
            <div className="field box" key={item.id}>
              <p className="is-size-5">Parcel {i+1}</p>
              <div className="field-body">

                <div className="field">
                  <p className="label">Parcel Type</p>
                  <div className="control is-clearfix">
                    <div className="select">
                      <select
                        name={`parcels.${i}.parcelType`}
                        {...register(`parcels.${i}.parcelType`)}
                      >
                        <option value=""></option>
                        <option value="Box">Box</option>
                        <option value="Envelope">Envelope</option>
                        <option value="Mixed">Mixed</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Quantity</p>
                  <div className="control is-clearfix">
                    <input
                      type="number"
                      className='input'
                      name={`parcels.${i}.quantity`}
                      {...register(`parcels.${i}.quantity`)}
                    />
                  </div>
                </div>

                <div className="field">
                  <p className="label">Length</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`parcels.${i}.length`}
                        {...register(`parcels.${i}.length`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.unitOfMeasurement].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Width</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`parcels.${i}.width`}
                        {...register(`parcels.${i}.width`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.unitOfMeasurement].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Depth</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`parcels.${i}.depth`}
                        {...register(`parcels.${i}.depth`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.unitOfMeasurement].length }</button>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <p className="label">Weight</p>
                  <div className="field has-addons has-addons-right">
                    <div className="control is-clearfix">
                      <input
                        className='input'
                        type="number"
                        step="any"
                        name={`parcels.${i}.weight`}
                        {...register(`parcels.${i}.weight`)}
                      />
                    </div>
                    <div className="control is-clearfix">
                    <button className='button is-static'>{ unitsFormat[values.unitOfMeasurement].weight }</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          ))}

          {fields.length > 0
            ? <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => prevStep() }
              >Previous</button>
            </p>
            <p className="control">
              <button
                type="submit"
                className='button is-black'
              >Finish</button>
            </p>
          </div>
            : <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className='button is-black'
                onClick={() => prevStep() }
              >Previous</button>
            </p>
          </div>
          }
          
        </form>
      </div>

    </section>
  );
  /* eslint-enable */
};

export default QuotesLTLParcels;
