
// To check:
// 1. Postcode exists                       => done
// 2. Kilos is between min and max range    => 

// 3. Zone exists                           => done

import React from 'react';
import Parser from 'html-react-parser';
import { data } from './data';

class QuotesDAPResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.values,
      ready: false,
      error: undefined,
      costs: {
        deliveryFees: undefined,
        fuelSurchage: undefined,
        carbonTax: undefined,
        accessorialFees: undefined,
        terminalFees: undefined,
        navFees: undefined,
        handlingFees: undefined,
        COVIDAirportFees: undefined,
        total: undefined,
      },
      zone: undefined
    };
  }

  async componentDidMount () {
    const { postalCode, from } = this.state;
    const { postcodes, method } = data[from];
    const zone = data[from].postcodes[postalCode.toUpperCase()];

    // Validate the postcode
    if (!(postalCode.toUpperCase() in postcodes)) {
      this.setState({ error: "Unfortunately, we don't serve this postcode." });  
      return;
    }

    // Validate the zone
    if (!(zone in data[from].zones)) {
      this.setState({ error: "Unfortunately, we don't serve this zone." });  
      return;
    }

    await this.preparePackages();
    const chargeableWeight = await this.calculateWeights();

    // Validate the max eight
    const { rates } = data[from].zones[zone];
    const maxValues = rates.map(obj => {
      return obj.max;
    });

    if (chargeableWeight > Math.max(...maxValues)) {
      this.setState({ error: `Unfortunately, the maximum total weight is ${Math.max(...maxValues)}kg.`});
      return;
    }

    if (method === "weights") {
      await this.calculateTotalsWeights();
    } else if (method === "skids" ) {
      await this.calculateTotalsSkids();
    } else {
      // Change for some error
      await this.calculateTotalsWeights();
    }
    this.setState({ ready: true });
  }

  preparePackages = async() => {
    const { packages, units, postalCode, from } = this.state;

    for (let i = 0; i < packages.length; i+=1) {
      packages[i].quantity = parseInt(packages[i].quantity, 10);
      packages[i].pieces = parseInt(packages[i].pieces, 10);

      if (units === "imperial") {
        packages[i].length = parseFloat(packages[i].length) * 2.54;
        packages[i].width = parseFloat(packages[i].width) * 2.54;
        packages[i].height = parseFloat(packages[i].height) * 2.54;
        packages[i].weight = parseFloat(packages[i].weight) * 0.453592;
      } else {
        packages[i].length = parseFloat(packages[i].length);
        packages[i].width = parseFloat(packages[i].width);
        packages[i].height = parseFloat(packages[i].height);
        packages[i].weight = parseFloat(packages[i].weight);
      }
    }
    this.setState({ ...packages, zone: data[from].postcodes[postalCode.toUpperCase()] });
  };

  calculateWeights = async() => {
    const { packages } = this.state;
    let chargeableWeight;
    let totalGrossWeight = 0;
    let totalVolumetricWeight = 0;

    for (let i = 0; i < packages.length; i+=1) {
      const cbm = packages[i].quantity * packages[i].length * packages[i].width * packages[i].height;
      const volumetricWeight = cbm / 0.006;
      const grossWeight = packages[i].quantity * packages[i].weight;
      packages[i].cbm = cbm;
      packages[i].volumetricWeight = volumetricWeight;
      packages[i].grossWeight = grossWeight;

      totalGrossWeight += grossWeight;
      totalVolumetricWeight += volumetricWeight;
    }

    if (totalGrossWeight > totalVolumetricWeight) {
      chargeableWeight = totalGrossWeight;
    } else {
      chargeableWeight = totalVolumetricWeight;
    }

    // for (let i = 0; i < packages.length; i+=1) {
    //   const cbm = (packages[i].quantity * (packages[i].length/100) * (packages[i].width/100) * (packages[i].height/100));
    //   const volumetricWeight = cbm / 0.006;
    //   const grossWeight = packages[i].quantity * packages[i].weight;
    //   packages[i].cbm = cbm;
    //   packages[i].volumetricWeight = volumetricWeight;
    //   packages[i].grossWeight = grossWeight;
    //   totalVolumetricWeight += volumetricWeight;
    //   totalGrossWeight += grossWeight;
    // }
 
    this.setState({
      packages,
      chargeableWeight
    });

    return chargeableWeight;
  }

  calculateTotalsSkids = async() => {
    const { chargeableWeight, zone, from, skids } = this.state;
    const { rates } = data[from].zones[zone];
    const deliveryFees = rates.find(x => x.skids === parseInt(skids, 10)).price;
    const { fuelSurchage, carbonTax, terminalFees, handlingFees, COVIDAirportFees } = data[from];

    const totalFuelSurchage = deliveryFees * fuelSurchage;
    const totalCarbonTax = (totalFuelSurchage + deliveryFees) * carbonTax;
    const navFees = chargeableWeight * 0.06;

    this.setState({
      costs: {
        deliveryFees,
        fuelSurchage: totalFuelSurchage,
        carbonTax: totalCarbonTax,
        terminalFees,
        navFees,
        handlingFees,
        COVIDAirportFees,
        total: deliveryFees + totalFuelSurchage + totalCarbonTax + terminalFees + navFees + handlingFees + COVIDAirportFees
      }
    });
  }

  calculateTotalsWeights = async() => {
    const { chargeableWeight, zone, from } = this.state;
    const { rates } = data[from].zones[zone];
    const { fuelSurchage, carbonTax, terminalFees, handlingFees, COVIDAirportFees } = data[from];
    let deliveryFees = 0;
    for (let i = 0; i < data[from].perKilo.length; i+=1) {
      if (chargeableWeight > data[from].perKilo[i].min && chargeableWeight < data[from].perKilo[i].max ) {
        deliveryFees += chargeableWeight * data[from].perKilo[i].price;
      }
    }

    for (let i = 0; i < rates.length; i+=1) {
      if (chargeableWeight > rates[i].min && chargeableWeight < rates[i].max ) {
        deliveryFees += rates[i].price;
        if ("multiply" in rates[i]) {
          deliveryFees += rates[i].multiply * chargeableWeight;
        }
      }
    }

    const totalFuelSurchage = deliveryFees * fuelSurchage;
    const totalCarbonTax = (totalFuelSurchage + deliveryFees) * carbonTax;
    const navFees = chargeableWeight * 0.06;

    this.setState({
      costs: {
        deliveryFees,
        fuelSurchage: totalFuelSurchage,
        carbonTax: totalCarbonTax,
        terminalFees,
        navFees,
        handlingFees,
        COVIDAirportFees,
        total: deliveryFees + totalFuelSurchage + totalCarbonTax + terminalFees + navFees + handlingFees + COVIDAirportFees
      }
    });
  }

  render() {
    const { prevStep, nextStep } = this.props;
    const { error, ready, chargeableWeight, costs, from } = this.state;
    return (
      <section className="section hero">
        {error &&
          <div className="box">{ error }</div>
        }
        {ready &&
          <div>
            <div className="box"><>{ Parser(data[from].disclaimer) }</></div>
            <div className="box">
                  <p>Total chargeable weight: { chargeableWeight.toFixed(2) }kg</p>
                  <p>Delivery Fees: { costs.deliveryFees.toFixed(2) } USD</p>
                  <p>Fuel Surchage: { costs.fuelSurchage.toFixed(2) } USD</p>
                  <p>Carbon Tax: { costs.carbonTax.toFixed(2) } USD</p>
                  <p>Terminal Fees: { costs.terminalFees.toFixed(2) } USD</p>
                  <p>Nav Fees: { costs.navFees.toFixed(2) } USD</p>
                  { costs.COVIDAirportFees > 0 && 
                    <p>COVID Aiport Fees: { costs.COVIDAirportFees.toFixed(2) } USD</p>
                  }
                  <p>Handling Fees: { costs.handlingFees.toFixed(2) } USD</p>
                  <p><strong>Total : { costs.total.toFixed(2) } USD</strong></p>
            </div>
          </div>
        }
        <div className="field is-grouped">
          <p className="control">
            <button
              type="button"
              className='button is-black'
              onClick={() => prevStep() }
            >Previous</button>
          </p>
          <p className="control">
            <button
              type="button"
              className='button is-black'
              onClick={() => nextStep() }
            >Book it</button>
          </p>
        </div>
        
      </section>
    );
  }
};

export default QuotesDAPResult;