import React from 'react';
import { toastr } from 'react-redux-toastr';
import QuotesDAPBasicInformation from './basicInformation';
import QuotesDAPAddress from './address';
import QuotesDAPPackages from './packages';
import QuotesDAPResult from './result';
import GuestNav from '../../../components/Navigation/GuestNav';

export default class QuotesDAP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      email: '',
      from: '',
      name: '',
      company: '',
      phone: '',
      street: '',
      city: '',
      postalCode: '',
      region: '',
      country: '',
      hoursOfOperation: {
        from: undefined,
        to: undefined
      },
      areaType: '',
      units: 'metric',
      skids: 0,
      packages: []
    };
    // this.state = {
    //   step: 2,
    //   email: 'rom@rom.com',
    //   from: 'YYZ',
    //   name: 'Rom',
    //   company: 'Rom',
    //   phone: 'Rom',
    //   street: 'Rom',
    //   city: 'Rom',
    //   postalCode: 'T1S',
    //   region: 'Rom',
    //   country: 'Rom',
    //   hoursOfOperation: 'Rom',
    //   areaType: 'residential',
    //   units: 'metric',
    //   packages: []
    // };
    // this.state = {
    //   step: 3,
    //   email: 'romaziel@gmail.com',
    //   from: 'YVR',
    //   name: 'Rom',
    //   company: 'Rom',
    //   phone: '123',
    //   street: 'Rom',
    //   city: 'Rom',
    //   postalCode: 'V5L',
    //   region: 'Rom',
    //   country: 'Rom',
    //   hoursOfOperation: 'Rom',
    //   areaType: 'Rom',
      // units: 'metric',
      // packages: [
      //   { 'format': 'pallet', 'quantity': 1, 'pieces': 1, 'length': 2.03, 'width': 0.25, 'height': 1.2, 'weight': 150 },
      //   { 'format': 'pallet', 'quantity': 1, 'pieces': 1, 'length': 1.22, 'width': 1.02, 'height': 1.2, 'weight': 100 },
      //   { 'format': 'pallet', 'quantity': 1, 'pieces': 1, 'length': 1.5, 'width': 1.1, 'height': 1.1, 'weight': 400 },
      //   { 'format': 'pallet', 'quantity': 2, 'pieces': 1, 'length': 1.22, 'width': 1.02, 'height': 1.55, 'weight': 300 },
      //   { 'format': 'pallet', 'quantity': 1, 'pieces': 1, 'length': 1.55, 'width': 1.55, 'height': 1.55, 'weight': 700 }
      // ]
    //   packages: [
    //     { 'format': 'pallet', 'quantity': 1, 'pieces': 1, 'length': 1.5, 'width': 1.1, 'height': 1.1, 'weight': 400 },
    //     { 'format': 'pallet', 'quantity': 2, 'pieces': 1, 'length': 1.22, 'width': 1.02, 'height': 0.75, 'weight': 150 }
    //   ]
    // };
    // this.state = {
    //   step: 3,
    //   email: 'romaziel@gmail.com',
    //   from: 'YUL',
    //   name: 'Rom',
    //   company: 'Rom',
    //   phone: '123',
    //   street: 'Rom',
    //   city: 'Rom',
    //   postalCode: 'J4Y',
    //   region: 'Rom',
    //   country: 'Rom',
    //   hoursOfOperation: 'Rom',
    //   areaType: 'Rom',
    //   units: 'metric',
    //   skids: 5,
    //   packages: [
    //     { 'format': 'pallet', 'quantity': 2, 'pieces': 1, 'length': 1.22, 'width': 1.02, 'height': 1.20, 'weight': 125.5 }
    //   ]
    // };
  }

  componentDidMount = () => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
  };

  finish = () => {
    toastr.error('', 'Coming Soon in next update.');
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  handleTimeChange = (mode, value) => {
    const { hoursOfOperation } = this.state;
    this.setState({ hoursOfOperation: {
      ...hoursOfOperation,
      [mode]: value
    } });
  }

  updatePackages = (packages) => {
    this.setState({ packages });
  }

  /* eslint-disable */
  render() {
    const { step } = this.state;
    const { units, email, from, name, company, phone, street, city, postalCode, region, country, hoursOfOperation, areaType, packages, skids } = this.state;
    const values = { units, email, from, name, company, phone, street, city, postalCode, region, country, hoursOfOperation, areaType, packages, skids }

    switch (step) {
      case 1:
        return (
          <section className="section hero">
            <div className="hero-head">
              <GuestNav />
            </div>
            <div className="hero-body">
              <div className="container">
                <QuotesDAPBasicInformation
                  nextStep={ this.nextStep }
                  handleChange={ this.handleChange }
                  values={ values }
                />
              </div>
            </div>
          </section>
        );
      case 2:
        return (
          <section className="section hero">
            <div className="hero-head">
              <GuestNav />
            </div>
            <div className="hero-body">
              <div className="container">
                <QuotesDAPPackages
                  prevStep={ this.prevStep }
                  nextStep={ this.nextStep }
                  handleChange={ this.handleChange }
                  updatePackages={ this.updatePackages }
                  values={ values }
                />
              </div>
            </div>
          </section>
        );
        case 3:
          return (
            <section className="section hero">
              <div className="hero-head">
                <GuestNav />
              </div>
              <div className="hero-body">
                <div className="container">
                  <QuotesDAPResult
                    prevStep={ this.prevStep }
                    nextStep={ this.nextStep }
                    values={ values }
                  />
                </div>
              </div>
            </section>
          );
        case 4:
          return (
            <section className="section hero">
              <div className="hero-head">
                <GuestNav />
              </div>
              <div className="hero-body">
                <div className="container">
                  <QuotesDAPAddress
                    prevStep={ this.prevStep }
                    finish={ this.finish }
                    handleChange={ this.handleChange }
                    handleTimeChange={ this.handleTimeChange }
                    values={ values }
                  />
                </div>
              </div>
            </section>
          );
      default:
    }
  }
  /* eslint-enable */
};
