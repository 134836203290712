import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';
import { postcodes } from 'data/postcodes';

const schema = yup.object().shape({
  from: yup.string().min(3).required(),
  email: yup.string().email().required(),
  name: yup.string().min(3).required(),
  company: yup.string().min(3).required(),
  phone: yup.string().min(3).required(),
  street: yup.string().min(3).required(),
  city: yup.string().min(3).required(),
  postalCode: yup.string().min(3).max(3).required(),
  region: yup.string().min(3).required(),
  country: yup.string().min(3).required(),
  areaType: yup.string().min(3).required(),
  hoursOfOperation: yup.object().shape({
    from: yup.string().min(1).required(),
    to: yup.string().min(1).required()
  })
});

const QuotesDAPAddress = ({ prevStep, finish, handleChange, handleTimeChange, values }) => {
  const { control, register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const copyValues = values;
    const city = postcodes[values.postalCode];
    if (city) {
      copyValues.city = city;
    }
    reset(copyValues);
  }, []);

  const onSubmitHandler = () => {
    finish();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
        
        <div className='block'>
          <h2 className='title'>From</h2>
          <div className="field">
            <div className="control is-clearfix">
              <div
                className={classNames('select', {
                  'is-danger': errors.from,
                })}
              >
                <select
                  {...register('from')}
                  readOnly
                  disabled
                  name="from"
                  onChange={handleChange('from')}
                >
                  <option value="">Select a value</option>
                  <option value="YYC">YYC (Calgary)</option>
                  <option value="YEG">YEG (Edmonton)</option>
                  <option value="YHZ">YHZ (Halifax)</option>
                  <option value="YVR">YVR (Vancouver)</option>
                  <option value="YYZ">YYZ (Toronto)</option>
                  <option value="YUL">YUL (Montreal)</option>
                </select>
              </div>
            </div>
            {errors.companyType && (
              <ErrorMessage text='Invalid area type.' />
            )}
          </div>
        </div>

        <div className='block'>
          <h2 className='title'>To</h2>
          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Name</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.name,
                    })}
                    name="name"
                    {...register('name')}
                    onChange={handleChange('name')}
                    onInput={handleChange('name')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid name.' />
                )}
              </div>
              <div className="field">
                <p className="label">Email</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.email,
                    })}
                    name="email"
                    {...register('email')}
                    onChange={handleChange('email')}
                    onInput={handleChange('email')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid email.' />
                )}
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Phone number</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.phone,
                    })}
                    name="phone"
                    type="phone"
                    {...register('phone')}
                    onChange={handleChange('phone')}
                    onInput={handleChange('phone')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid phone number.' />
                )}
              </div>
              <div className="field">
                <p className="label">Company</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.company,
                    })}
                    name="company"
                    {...register('company')}
                    onChange={handleChange('company')}
                    onInput={handleChange('company')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid company.' />
                )}
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Street</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.street,
                    })}
                    name="street"
                    {...register('street')}
                    onChange={handleChange('street')}
                    onInput={handleChange('street')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid street.' />
                )}
              </div>
              <div className="field">
                <p className="label">City</p>
                <div className="control is-clearfix">
                  <input
                    {...register('city')}
                    className={classNames('input', {
                      'is-danger': errors.city,
                    })}
                    name="city"
                    onChange={handleChange('city')}
                    onInput={handleChange('city')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid city.' />
                )}
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Postal Code</p>
                <div className="control is-clearfix">
                  <input
                    {...register('postalCode')}
                    readOnly
                    disabled
                    className={classNames('input', {
                      'is-danger': errors.postalCode,
                    })}
                    name="postalCode"
                    maxLength="3"
                    onChange={handleChange('postalCode')}
                    onInput={handleChange('postalCode')}
                    />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid postal code.' />
                )}
              </div>
              <div className="field">
                <p className="label">State/Province/Region</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.region,
                    })}
                    name="region"
                    {...register('region')}
                    onChange={handleChange('region')}
                    onInput={handleChange('region')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid region.' />
                )}
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Country</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': errors.country,
                    })}
                    name="country"
                    {...register('country')}
                    onChange={handleChange('country')}
                    onInput={handleChange('country')}
                  />
                </div>
                {errors.name && (
                  <ErrorMessage text='Invalid country.' />
                )}
              </div>
              
              <div className="field">
                <p className="label">Area Type</p>
                <div className="control is-clearfix">
                  <div
                    className={classNames('select is-fullwidth', {
                      'is-danger': errors.areaType,
                    })}
                  >
                    <select
                      name="areaType"
                      {...register('areaType')}
                      onChange={handleChange('areaType')}
                      onInput={handleChange('areaType')}
                    >
                      <option value="">Select a value</option>
                      <option value="residential">Residential</option>
                      <option value="commercial">Commercial</option>
                    </select>
                  </div>
                </div>
                {errors.companyType && (
                  <ErrorMessage text='Invalid area type.' />
                )}
              </div>
            </div>
          </div>

        </div>

        <div className='block'>
          <h2 className='title'>Hours of operations</h2>
          <div className="field">
            <div className="field-body">
              <div className="field">
                <p className="label">From</p>
                <div className="control is-clearfix is-expanded">
                  <div
                    className={classNames('select', {
                      'is-danger': errors.hoursOfOperation,
                    })}
                  >
                    <Controller
                      control={control}
                      {...register('hoursOfOperation.from')}
                      name='hoursOfOperation.from'
                      render={({field}) => (
                        <DatePicker
                          onChange={(date) => {
                            handleTimeChange('from', date);
                            field.onChange(date);
                          }}
                          selected={field.value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={15}
                          timeCaption="From"
                          dateFormat="h:mm aa"
                        />
                      )}
                    />
                  </div>
                </div>
                {errors.hoursOfOperation && (
                  <ErrorMessage text='Invalid time selected.' />
                )}
              </div>

              <div className="field">
                <p className="label">To</p>
                <div className="control is-clearfix is-expanded">
                  <div
                    className={classNames('select', {
                      'is-danger': errors.hoursOfOperation,
                    })}
                  >
                    <Controller
                      control={control}
                      {...register('hoursOfOperation.to')}
                      name='hoursOfOperation.to'
                      render={({field}) => (
                        <DatePicker
                          onChange={(date) => {
                            handleTimeChange('to', date);
                            field.onChange(date);
                          }}
                          selected={field.value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={15}
                          timeCaption="To"
                          dateFormat="h:mm aa"
                        />
                      )}
                    />
                  </div>
                </div>
                {errors.hoursOfOperation && (
                  <ErrorMessage text='Invalid time selected.' />
                )}
              </div>


            </div>
          </div>
        </div>

        <div className="field is-grouped">
          <p className="control">
            <button
              type="button"
              className='button is-black'
              onClick={() => prevStep() }
            >Previous</button>
          </p>
          <p className="control">
            <button
              type="submit"
              className='button is-black'
            >Finish</button>
          </p>
        </div>

      </form>
    </div>
  );
};

export default QuotesDAPAddress;
