import React, { useEffect } from 'react';
import { useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';

const schema = yup.object().shape({
  sender: yup.object().shape({
    number: yup.number().required().positive().integer().min(1).max(999999),
    postalCode: yup.string().min(3).required(),
    countryCode: yup.string().min(2).max(2).required()
  }),
  consignee: yup.object().shape({
    number: yup.number().required().positive().integer().min(1).max(999999),
    postalCode: yup.string().min(3).required(),
    countryCode: yup.string().min(2).max(2).required()
  }),
	deliveryType: yup.mixed().oneOf(['GRD', 'AIR']).defined()
});

const QuotesLTLAddress = ({ nextStep, handleChange, handleChangeObject, values }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const copyValues = values;
    reset(copyValues);
  }, []);

  const onSubmitHandler = () => {
    nextStep();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">

        <div className='block'>
          <h2 className='title'>Sender</h2>
          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Street Number</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': (errors.sender && "number" in errors.sender),
                    })}
                    name="sender.number"
                    {...register('sender.number')}
										onChange={handleChangeObject('sender', 'number')}
										onInput={handleChangeObject('sender', 'number')}
										type="number"
                  />
                </div>
			            {(errors.sender && "number" in errors.sender) && (
                  <ErrorMessage text='Invalid street number. Must be between 1 and 999999.' />
                )}
              </div>
              <div className="field">
                <p className="label">Postal Code</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': (errors.sender && "postalCode" in errors.sender),
                    })}
                    name="sender.postalCode"
                    {...register('sender.postalCode')}
										onChange={handleChangeObject('sender', 'postalCode')}
										onInput={handleChangeObject('sender', 'postalCode')}
                  />
                </div>
                {(errors.sender && "postalCode" in errors.sender) && (
                  <ErrorMessage text='Invalid postal code. Must be at least 3 characters.' />
                )}
              </div>

              <div className="field">
                <p className="label">Country</p>
                <div className="control is-clearfix">
                  <div
                    className={classNames('select', {
                      'is-danger': (errors.sender && "countryCode" in errors.sender),
                    })}
                  >
                    <select
                      {...register('sender.countryCode')}
											onChange={handleChangeObject('sender', 'countryCode')}
                      name="sender.countryCode"
                    >
                      <option value="">Country</option>
                      <option value="CA">Canada</option>
                    </select>
                  </div>
                </div>
                {(errors.sender && "countryCode" in errors.sender) && (
                  <ErrorMessage text='Invalid country code. We only support CA (Canada) at the moment.' />
                )}
              </div>
            </div>
          </div>

        </div>

        <div className='block'>
          <h2 className='title'>Consignee</h2>
          <div className='field'>
            <div className='field-body'>
              <div className="field">
                <p className="label">Street Number</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': (errors.consignee && "number" in errors.consignee),
                    })}
                    name="consignee.number"
                    {...register('consignee.number')}
										onChange={handleChangeObject('consignee', 'number')}
										onInput={handleChangeObject('consignee', 'number')}
										type="number"
                  />
                </div>
                {(errors.consignee && "number" in errors.consignee) && (
                  <ErrorMessage text='Invalid street number. Must be between 1 and 999999.' />
                )}
              </div>
              <div className="field">
                <p className="label">Postal Code</p>
                <div className="control is-clearfix">
                  <input
                    className={classNames('input', {
                      'is-danger': (errors.consignee && "postalCode" in errors.consignee),
                    })}
                    name="consignee.postalCode"
                    {...register('consignee.postalCode')}
										onChange={handleChangeObject('consignee', 'postalCode')}
										onInput={handleChangeObject('consignee', 'postalCode')}
                  />
                </div>
                {(errors.consignee && "postalCode" in errors.consignee ) && (
                  <ErrorMessage text='Invalid postal code. Must be at least 3 characters.' />
                )}
              </div>

              <div className="field">
                <p className="label">Country</p>
                <div className="control is-clearfix">
                  <div
                    className={classNames('select', {
                      'is-danger': (errors.consignee && "countryCode" in errors.consignee),
                    })}
                  >
                    <select
                      {...register('consignee.countryCode')}
											onChange={handleChangeObject('consignee', 'countryCode')}
                      name="consignee.countryCode"
                    >
											<option value="">Choose</option>
                      <option value="CA">Canada</option>
                    </select>
                  </div>
                </div>
                {(errors.consignee && "countryCode" in errors.consignee) && (
                  <ErrorMessage text='Invalid country code. We only support CA (Canada) at the moment.' />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='block'>
          <div className='field'>
            <div className='field-body'>

              <div className="field">
                <p className="label">Delivery Type</p>
                <div className="control is-clearfix">
                  <div
                    className={classNames('select', {
                      'is-danger': errors.deliveryType,
                    })}
                  >
                    <select
                      {...register('deliveryType')}
											onChange={handleChange('deliveryType')}
                      name="deliveryType"
                    >
											<option value="">Choose</option>
                      <option value="GRD">Ground</option>
                      <option value="AIR">Air</option>
                    </select>
                  </div>
                </div>
                {errors.deliveryType && (
                  <ErrorMessage text='Invalid delivery type. We only support Air and Ground at the moment.' />
                )}
              </div>
            </div>
          </div>
        </div>

				<div className='field'>
					<button type='submit' className='button is-black'>Next</button>
				</div>

      </form>
    </div>
  );
};

export default QuotesLTLAddress;
